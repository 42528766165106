import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { addLocaleData, IntlProvider } from "react-intl";
import locale_fr from "react-intl/locale-data/fr";
import moment from "moment";

import Skeleton from "./Skeleton";
import ErrorPage from "../pages/Error.page";
import AuthPage from "../pages/Auth.page";

import messages_fr from "../translations/fr.json";
import Layout from "../pages/Layout";
import AuthCallback from "../pages/AuthCallback";

const MomentConfigs = require("../configs/moment.conf");

addLocaleData([...locale_fr]);
const messages = {
  fr: messages_fr,
  en: null,
};

class Routes extends React.Component {
  constructor(props) {
    super(props);

    // Remove for the moment, this cookie will be usefull to persist filters if needed
    try {
      document.getElementById("errorMessage").remove();
    } catch (e) {}

    // get wanted path in case user is redirected to signin
    if (
      !this.props.commonStore.getCookie("wantedPath") &&
      window.location.pathname.search("signin") === -1 &&
      window.location.pathname.search("signup") === -1
    ) {
      this.props.commonStore.setCookie("wantedPath", window.location.pathname, {
        expires: new Date(new Date().getTime() + 10 * 60000),
      });
    }
  }

  render() {
    const locale = "fr";
    this.props.commonStore.setLocale(locale);
    if (locale === "fr") MomentConfigs.setMomentFr();
    else moment.locale("en");

    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Layout>
          <Switch>
            <Route path="/error/:errorCode/:errorType?">
              <ErrorPage />
            </Route>
            <Route path="/signin/callback/:hash/:token">
              <AuthCallback />
            </Route>
            <Route path="/(signup|signin|welcome)">
              <AuthPage />
            </Route>
            <Route path="/">
              <Skeleton />
            </Route>
          </Switch>
        </Layout>
      </IntlProvider>
    );
  }
}

export default withRouter(inject("userStore", "commonStore")(observer(Routes)));
