import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { orderBy } from "lodash";
import HighlightedButton from "../../buttons/HighlightedButton";
import useStyle from "./tasks/Task.css";
import { LOADING } from "../../../asyncStatus";

const CreateTask = ({
  intl: { formatMessage },
  taskStore: { createTask, createTaskStatus },
  categoryStore: { categories, fetchCategories },
  timeGroupId,
  isSuitcase,
}) => {
  const classes = useStyle();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    isCreateMode && categories.length === 0 && fetchCategories();
  }, [isCreateMode, categories]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await createTask({ name, categoryId: category, timeGroupId, isSuitcase });
    setIsCreateMode(false);
    setName(null);
    setCategory(null);

    enqueueSnackbar(formatMessage({ id: `snackbar.label.created` }), {
      variant: "success",
      action: (key) => <></>,
    });
  };

  return (
    <div className={classes.root}>
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="row"
          className={classes.rootContainer}
          spacing={isMobile ? 1 : undefined}
        >
          {isCreateMode ? (
            <>
              {!isMobile && (
                <Grid item xs={1} className={classes.colZero}>
                  <Checkbox checked={false} />
                </Grid>
              )}

              <Grid item xs={12} sm={6} className={classes.colZero}>
                <TextField
                  fullWidth
                  label={formatMessage({ id: "task.action.create.name.label" })}
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? classes.colZero : classes.col}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="category">
                    <FormattedMessage id="task.action.create.category.label" />
                  </InputLabel>
                  <Select
                    labelId="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label={formatMessage({
                      id: "task.action.create.category.label",
                    })}
                    required
                  >
                    <MenuItem value="">{/* <em>None</em> */}</MenuItem>
                    {orderBy(categories, ["ordre", "desc"]).map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={3}
                className={classes.col}
                justify={isMobile ? "space-around" : "flex-end"}
              >
                {createTaskStatus === LOADING ? (
                  <Grid item>
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="text"
                        style={{ marginRight: 16 }}
                        onClick={() => setIsCreateMode(false)}
                      >
                        <FormattedMessage id="cta.cancel" />
                      </Button>
                    </Grid>
                    <Grid item>
                      <HighlightedButton type="submit">
                        <FormattedMessage id="task.action.create.cta.label" />
                      </HighlightedButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item className={classes.colZero}>
                <IconButton
                  size="small"
                  onClick={() => setIsCreateMode(true)}
                  color="secondary"
                  className={classes.btn}
                >
                  <i className="fas fa-plus"></i>
                </IconButton>
              </Grid>

              <Grid item className={classes.col}>
                <Typography
                  variant="body1"
                  className={classes.text}
                  onClick={() => setIsCreateMode(true)}
                >
                  <FormattedMessage id="task.action.create.label" />
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default injectIntl(
  inject("taskStore", "categoryStore")(observer(CreateTask))
);
