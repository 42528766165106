import { Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { orderBy } from "lodash";

import TaskRow from "./TaskRow";

const SubTasks = ({ taskStore: { taskSubTasks }, masterStaticTaskId }) => {

  const sortedTasks = orderBy(
    taskSubTasks(masterStaticTaskId).map((t) => ({
      ...t,
      usefulness: t.usefulness === null ? -1 : t.usefulness,
      budget: t.budget === null ? -1 : t.budget,
    })),
    ["usefulness", "budget", "name"],
    ["desc", "desc", "asc"]
  ).map((t) => ({
    ...t,
    usefulness: t.usefulness === -1 ? null : t.usefulness,
    budget: t.budget === -1 ? null : t.budget,
  }));

  return (
    <Grid item container style={{ padding: "0 32px" }}>
      {sortedTasks.map((task) => (
        <TaskRow task={task} key={task.id} />
      ))}
    </Grid>
  );
};

export default inject("taskStore")(observer(SubTasks));
