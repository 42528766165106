import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";

import HighlightedButton from "../buttons/HighlightedButton";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
  label: {
    fontWeight: 600,
    maxHeight: "10rem",
    overflow: "hidden",
  },
  item: {
    padding: 8,
  },
}));

const ActionCard = ({
  label,
  ctaLabel,
  ctaIcon,
  onCtaClick,
  iconButton,
  height = 284,
  ...props
}) => {
  const classes = useStyle();

  return (
    <Grid
      {...props}
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
      style={{ height }}
    >
      {iconButton && (
        <Grid item className={classes.item}>
          {iconButton}
        </Grid>
      )}
      <Grid item className={classes.item}>
        <Tooltip title={label}>
          <Typography variant="body1" className={classes.label} align="center">
            {label}
          </Typography>
        </Tooltip>
      </Grid>
      {ctaLabel && (
        <Grid item className={classes.item}>
          <HighlightedButton startIcon={ctaIcon} onClick={onCtaClick}>
            {ctaLabel}
          </HighlightedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ActionCard;
