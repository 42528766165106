import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import ChecklistFiltersBar from "../components/checklist/main/ChecklistFiltersBar";
import Suitcase from "../components/checklist/main/Suitcase";
import MainAppHeader from "../components/header/MainAppHeader";

const SuitcasePage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MainAppHeader isSuitcase />
      </Grid>
      <Grid item xs={12}>
        <ChecklistFiltersBar isSuitcase />
      </Grid>
      <Grid item xs={12}>
        <Suitcase />
      </Grid>
    </Grid>
  );
};

export default SuitcasePage;
