import React from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    // height: 250,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  imgRoot: {
    position: "relative",
    width: "100%",
    height: 220,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  productActions: {
    position: "absolute",
    padding: 8,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  footer: {
    position: "relative",
    width: "100%",
    height: 64,
    padding: 8,
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  footerActions: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  footerWithActions: {
    height: 106,
    [theme.breakpoints.down("xs")]: {
      height: 87,
    },
  },
  iconBtn: {
    backgroundColor: "white",
    marginLeft: 8,
    width: 32,
    height: 32,
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
  },
  picture: {
    width: "100%",
    height: "auto",
  },
}));

const ProductCard = ({
  title,
  achat,
  wpProductId,
  marque,
  price,
  onEdit,
  onDelete,
  productUrl,
  pictureUrl,
  FooterActions,
}) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div
        className={classes.imgRoot}
        style={{ backgroundImage: `url(${pictureUrl})` }}
      >
        <div className={classes.productActions}>
          {!wpProductId && (
            <IconButton
              size="small"
              color="secondary"
              className={classes.iconBtn}
              onClick={onEdit}
            >
              <i className="far fa-edit"></i>
            </IconButton>
          )}
          <IconButton
            size="small"
            color="secondary"
            className={classes.iconBtn}
            onClick={onDelete}
          >
            <i className="far fa-trash-alt"></i>
          </IconButton>
          {productUrl && (
            <IconButton
              size="small"
              color="secondary"
              className={classes.iconBtn}
              component="a"
              href={productUrl}
              target="_blank"
              rel="nofollow"
            >
              <i className="fas fa-link"></i>
            </IconButton>
          )}
        </div>
      </div>

      <div
        className={`${classes.footer} ${
          FooterActions ? classes.footerWithActions : ""
        }`}
      >
        <div>
          <Tooltip title={title} enterTouchDelay={0}>
            <Typography noWrap variant="body1">
              {title}
            </Typography>
          </Tooltip>
          <Typography noWrap variant="body2">
            {marque} {price ? price + "€" : ""}
          </Typography>
        </div>

        {FooterActions && (
          <div className={classes.footerActions}>{FooterActions}</div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
