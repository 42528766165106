import { runInAction, makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import agent from "../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../asyncStatus";

class PurchaseStore {
  purchasesByTaskId = {};
  createPurchaseStatus = IDLE;
  fetchTaskPurchasesStatus = IDLE;
  deletePurchaseStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @return Return null means it doesn't fetch yet.
   */
  taskPurchases = computedFn((taskId) => {
    return this.purchasesByTaskId[taskId];
  });

  fetchTaskPurchases = async (taskId) => {
    try {
      this.fetchTaskPurchasesStatus = LOADING;

      const { data: purchases } = await agent.Purchase.getByTaskId(taskId);

      runInAction(() => {
        this.purchasesByTaskId = {
          ...this.purchasesByTaskId,
          [taskId]: purchases,
        };

        this.fetchTaskPurchasesStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchTaskPurchasesStatus = ERROR;
      });
    }
  };

  // TODO : need product to populate fields ...
  createPurchase = async (product, taskId) => {
    try {
      this.createPurchaseStatus = LOADING;

      const { data: createdPurchases } = await agent.Purchase.create({
        productId: product.id,
        taskId,
      });

      runInAction(() => {
        createdPurchases.forEach((cPurchase) => {
          this.addPurchase(cPurchase.taskId, cPurchase.id, {
            id: cPurchase.id,
            description: product.description,
            price: product.price,
            pictureUrl: product.pictureUrl,
            productUrl: product.productUrl,
            productId: product.id,
          });
        });

        this.createPurchaseStatus = SUCCESS;
      });

      return createdPurchases;
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.createPurchaseStatus = ERROR;
      });
    }
  };

  deletePurchase = async (purchaseId, taskId) => {
    try {
      this.deletePurchaseStatus = LOADING;

      await agent.Purchase.delete(purchaseId);

      runInAction(() => {
        const newTaskPurchases = this.purchasesByTaskId[taskId] || [];

        this.purchasesByTaskId = {
          ...this.purchasesByTaskId,
          [taskId]: newTaskPurchases.filter((elt) => elt.id !== purchaseId),
        };
        this.deletePurchaseStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.deletePurchaseStatus = ERROR;
      });
    }
  };

  addPurchase = (taskId, purchaseId, purchase) => {
    let elt =
      (this.purchasesByTaskId[taskId] || []).find((p) => p.id === purchaseId) ||
      {};

    elt = { ...elt, ...purchase };

    this.purchasesByTaskId[taskId] = (
      (this.purchasesByTaskId[taskId] || []).filter(
        (p) => p.id !== purchaseId
      ) || []
    ).concat([elt]);
  };

  removePurchase = (taskId, purchaseId) => {
    this.purchasesByTaskId[taskId] = this.purchasesByTaskId[taskId].filter(
      (p) => p.id != purchaseId
    );
  };
}

export default new PurchaseStore();
