import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/fn/promise/finally";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import authStore from "./stores/auth.store";
import userStore from "./stores/user.store";
import commonStore from "./stores/common.store";
import babyStore from "./stores/baby.store";
import TaskStore from "./stores/task.store";
import categoryStore from "./stores/category.store";
import purchaseStore from "./stores/purchase.store";
import productStore from "./stores/product.store";
import favoriteStore from "./stores/favorite.store";
import HttpsRedirect from "react-https-redirect";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./theme";
import ReactGA from "react-ga";

if (process.env.NODE_ENV !== "development" && process.env.REACT_APP_GA) {
  console.log("Initialize React GA");
  ReactGA.initialize(process.env.REACT_APP_GA, { debug: true });
  ReactGA.set({ location: "https://www.mababychecklist.fr" });
}

const taskStore = new TaskStore(babyStore, purchaseStore, favoriteStore);

const stores = {
  authStore,
  userStore,
  commonStore,
  taskStore,
  babyStore,
  categoryStore,
  productStore,
  purchaseStore,
  favoriteStore,
};

ReactDOM.render(
  <HttpsRedirect>
    <Provider {...stores}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
