import { CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { ERROR, LOADING, SUCCESS } from "../asyncStatus";

const AuthCallback = ({
  authStore: { signinWithMagicLinkExchange, signinWithMagicLinkExchangeStatus },
}) => {
  const { hash, token } = useParams();

  useEffect(() => {
    signinWithMagicLinkExchange(hash, token);
  }, []);

  if (signinWithMagicLinkExchangeStatus === LOADING)
    return <CircularProgress color="secondary" />;
  else if (signinWithMagicLinkExchangeStatus === ERROR)
    return <Redirect to={"/signin"} />;
  else if (signinWithMagicLinkExchangeStatus === SUCCESS)
    return <Redirect to={"/checklist"} />;
  else return null;
};

export default inject("authStore")(observer(AuthCallback));
