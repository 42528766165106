import React, { useState } from "react";
import { Button, Collapse, Grid, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";

import useStyle from "./Task.css";
import { TASK_TYPES } from "../../../../stores/task.store";
import TaskActions from "./TaskActions";
import SubTasks from "./SubTasks";
import ModelChoices from "./ModelChoices";
import NotesView from "./notes/NotesView";

const TaskExpandedZone = ({
  open,
  task: {
    usefulness,
    minQuantity,
    maxQuantity,
    description,
    modelPageUrl,
    status,
    type,
    staticTaskId,
    id,
    choices,
    notes,
    isSuitcase,
  },
  updateTaskStatus,
  setOpen,
}) => {
  const [needConfirm, setNeedConfirm] = useState(false);
  const classes = useStyle();

  const deleteTask = (e) => {
    if (usefulness >= 4) return setNeedConfirm(true);
    updateTaskStatus("deleted")(e);
  };

  return (
    <Collapse
      in={open}
      timeout={500}
      unmountOnExit
      mountOnEnter
      className={classes.expandedRoot}
    >
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12}>
          {description && (
            <div className={classes.descriptionContainer}>
              <Grid item container alignItems="center" spacing={1}>
                {minQuantity && (
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      <FormattedMessage
                        id="task.quantity.display"
                        values={{
                          quantity: `${minQuantity}${
                            maxQuantity ? " - " + maxQuantity : ""
                          }`,
                        }}
                      />
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="body1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  </Typography>
                </Grid>

                {modelPageUrl && (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      component="a"
                      href={modelPageUrl}
                      target="_blank"
                      onClick={() => setOpen(false)}
                    >
                      <FormattedMessage
                        id={`task.cta.${type ? type + "." : ""}url.label`}
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {type === TASK_TYPES.toFetch || !type ? (
            <TaskActions taskId={id} notes={notes} />
          ) : type === TASK_TYPES.master ? (
            <SubTasks masterStaticTaskId={staticTaskId} />
          ) : type === TASK_TYPES.admin ? (
            <NotesView taskId={id} />
          ) : (
            <ModelChoices taskId={id} updateTaskStatus={updateTaskStatus} />
          )}
        </Grid>
      </Grid>
      {status === "todo" && (
        <div
          className={
            needConfirm
              ? classes.deleteTaskRibbonHighlighted
              : classes.deleteTaskRibbon
          }
        >
          {needConfirm ? (
            <div className={classes.deleteConfirmRibbon}>
              <Typography variant="body1">
                <FormattedMessage id="task.cta.delete.confirm" />
              </Typography>

              <div>
                <Button
                  variant="text"
                  onClick={() => setNeedConfirm(false)}
                  style={{ marginRight: 16 }}
                >
                  <FormattedMessage id="cta.cancel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon color="secondary" />}
                  onClick={updateTaskStatus("deleted")}
                >
                  <FormattedMessage id="cta.confirm" />
                </Button>
              </div>
            </div>
          ) : (
            <Button
              variant="text"
              onClick={deleteTask}
              startIcon={<DeleteIcon color="secondary" />}
              size="large"
            >
              <FormattedMessage
                id={
                  isSuitcase
                    ? "task.cta.suitcase.remove"
                    : type === TASK_TYPES.master
                    ? "task.cta.master.remove"
                    : type === TASK_TYPES.admin
                    ? "task.cta.admin.remove"
                    : "task.cta.remove"
                }
              />
            </Button>
          )}
        </div>
      )}
    </Collapse>
  );
};

export default TaskExpandedZone;
