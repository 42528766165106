import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  cloudImg: {
    width: "90%",
    textAlign: "center",
    padding: "64px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0px",
    },
  },
  bigCount: {
    fontFamily: "CandyChicks",
    fontSize: "5rem",
    color: theme.palette.secondary.main,
    marginRight: 8,
    marginLeft: 8,
    marginTop: -16,
  },
  item: {
    margin: "8px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: 8,
    },
  },
  mainTitleItem: {},
}));
