import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FormattedMessage, injectIntl } from "react-intl";
import { inject, observer } from "mobx-react";
import handleViewport from "react-in-viewport";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import { getOr } from "lodash/fp";

import Tag from "../../../utils/Tag";
import HighlightedButton from "../../../buttons/HighlightedButton";
import TaskExpandedZone from "./TaskExpandedZone";
import useStyle from "./Task.css";
import { IDLE, LOADING, SUCCESS } from "../../../../asyncStatus";

const TaskRow = ({
  task: { name, usefulness, budget, id, status, inProgress } = {},
  task = {},
  intl: { formatMessage },
  taskStore: { updateTask },
  purchaseStore: { fetchTaskPurchases, taskPurchases },
  inViewport,
  forwardedRef,
  enterCount,
}) => {
  const [open, setOpen] = useState(false);
  const [currentTaskStatus, setCurrentTaskStatus] = useState(IDLE);
  const classes = useStyle();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateTaskStatus = (newStatus) => (e) => {
    setCurrentTaskStatus(LOADING);
    updateTask(id, { status: newStatus }, true).then(() => {
      setCurrentTaskStatus(SUCCESS);
    });
    setOpen(false);

    // Notification & Cancel action
    ["done", "deleted"].includes(newStatus) &&
      enqueueSnackbar(formatMessage({ id: `snackbar.label.${newStatus}` }), {
        variant: newStatus === "done" ? "success" : "warning",
        action: (key) => (
          <Button
            style={{ color: "white" }}
            size="large"
            variant="text"
            onClick={() => {
              updateTask(id, { status }, true);
              closeSnackbar(key);
            }}
          >
            <FormattedMessage id="cta.cancel" />
          </Button>
        ),
      });
  };

  useEffect(() => {
    if (
      inViewport &&
      enterCount === 1 &&
      task.categoryName === "Vêtements Bébé"
    ) {
      fetchTaskPurchases(id);
    }
  }, [inViewport, enterCount]);

  if (!inViewport && enterCount === 0) {
    return (
      <div
        ref={forwardedRef}
        className={classes.root}
        style={{ paddingLeft: 32 }}
      >
        ...
      </div>
    );
  }

  return (
    <div ref={forwardedRef} className={classes.root}>
      <Grid container direction="row" className={classes.rootContainer}>
        <Grid item className={classes.colZero}>
          {status === "deleted" ? (
            <>
              {currentTaskStatus === LOADING ? (
                <CircularProgress color="secondary" />
              ) : (
                <HighlightedButton
                  onClick={updateTaskStatus("todo")}
                  size="small"
                >
                  <FormattedMessage id="task.cta.remake" />
                </HighlightedButton>
              )}
            </>
          ) : (
            <>
              {currentTaskStatus === LOADING ? (
                <CircularProgress color="secondary" />
              ) : (
                <Checkbox
                  checked={status === "done"}
                  onChange={updateTaskStatus(
                    status === "todo" ? "done" : "todo"
                  )}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item container sm={10} className={classes.taskMainContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            className={`${classes.col} ${classes.name}`}
            onClick={() => setOpen(!open)}
          >
            <Tooltip title={name}>
              <Typography variant="body1" noWrap={!open}>
                {name +
                  (task.categoryName === "Vêtements Bébé" &&
                  task.minQuantity &&
                  !isMobile
                    ? ` (${getOr(0, "length", taskPurchases(id))}/${
                        task.minQuantity
                      })`
                    : "")}
              </Typography>
            </Tooltip>
            {!!(inProgress && !isMobile && status === "todo") && (
              <Tag label="En cours" className={classes.inProgress} />
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            className={`${classes.col} ${classes.tagPart}`}
            direction="row"
            justify="flex-start"
          >
            {!!(inProgress && isMobile && status === "todo") && (
              <Grid item>
                <div className={classes.col}>
                  <Tag label="En cours" className={classes.inProgress} />
                </div>
              </Grid>
            )}

            {task.categoryName === "Vêtements Bébé" &&
              task.minQuantity &&
              isMobile && (
                <Grid item>
                  <div className={classes.col}>
                    <Tag
                      label={`${getOr(0, "length", taskPurchases(id))}/${
                        task.minQuantity
                      }`}
                    />
                  </div>
                </Grid>
              )}

            <Grid item>
              <div className={`${classes.col} ${classes.usefulnessCol}`}>
                {usefulness >= 0 && usefulness !== null && (
                  <Tag
                    label={formatMessage({
                      id: `filter.label.usefulness.table.${usefulness}`,
                    })}
                    startIcon={
                      usefulness === 0 ? (
                        <i class="fas fa-thumbs-down"></i>
                      ) : usefulness === 5 ? (
                        <i class="fas fa-thumbs-up"></i>
                      ) : null
                    }
                  />
                )}
              </div>
            </Grid>

            <Grid item>
              <div className={`${classes.col} ${classes.budgetCol}`}>
                {budget && (
                  <Tag
                    fullWidth
                    label={formatMessage({
                      id: `filter.label.budget.${budget}`,
                    })}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={`${classes.col} ${classes.arrowCol}`}>
          {status === "todo" && (
            <IconButton
              variant="text"
              onClick={updateTaskStatus("deleted")}
              size="small"
              className={classes.deleteButton}
              disabled={currentTaskStatus === LOADING}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          )}

          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            color="secondary"
            className={classes.highlighted}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <TaskExpandedZone
          open={open}
          setOpen={setOpen}
          task={task}
          updateTaskStatus={updateTaskStatus}
        />
      </Grid>
    </div>
  );
};

export default injectIntl(
  handleViewport(inject("taskStore", "purchaseStore")(observer(TaskRow)))
);
