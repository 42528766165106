import { createMuiTheme } from "@material-ui/core/styles";

export const palette = {
  primary: {
    main: "#11283F",
    light: "#F4F7FB",
    ultraLight: "#FBFBFD",
    text: "#53596A",
    background: "#2E4862",
  },
  secondary: {
    light: "#fdf5db",
    main: "#F2B800",
  },
  background: {
    default: "#f2f2f2",
  },
};

const theme = createMuiTheme({
  palette: palette,
  typography: {
    useNextVariants: true,
    color: palette.primary.main,
    fontSize: 16,
    fontFamily: "Open Sans",
    letterSpacing: "-1.11px",
  },
  overrides: {
    MuiPaper: {
      root: {
        // boxShadow: "initial !important",
      },
    },
    MuiButton: {
      root: {
        textTransform: "initial !important",
        fontSize: "1rem",
        color: palette.primary.main,
        fontWeight: 600,
      },
      contained: {
        color: palette.primary.main,
      },
      containedPrimary: {
        color: "white",
      },
      sizeLarge: {
        padding: "8px 16px",
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 4,
      },
    },
    MuiTypography: {
      root: {
        color: palette.primary.text,
        fontSize: "16px",
        fontFamily: "Open Sans",
        letterSpacing: "-1.11px",
        paddingRight: "1.11px",
      },
      h1: {
        color: palette.primary.main,
        // fontSize: "2.375rem",
        fontSize: "2rem",
        fontWeight: 700,
      },
      h2: {
        color: palette.primary.main,
        fontSize: "1.4rem",
        fontWeight: 700,
      },
      h3: {
        color: palette.primary.main,
        fontSize: "1.2rem",
        fontWeight: 700,
      },
      h4: {
        color: palette.primary.main,
        fontSize: "1rem",
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: "1.125rem",
        fontWeight: 400,
        opacity: 0.5,
      },
      subtitle2: {
        fontSize: "1rem",
        fontWeight: 400,
        opacity: 0.5,
      },
      body2: {
        fontSize: "0.95rem",
      },
    },
  },
});

// theme = responsiveFontSizes(theme);

export default theme;
