import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import LinkField from "../../../../utils/LinkField";
import PictureField from "../../../../utils/PictureField";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  subfield: {
    maxWidth: 180,
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
    },
  },
}));

const CreateFavoriteStore = ({
  taskId, // bad way to send taskId to the children ...
  favorite = {},
  taskStore: { applyStrategyAndToggleInProgress },
  favoriteStore: { createFavorite, createFavoriteStatus, addFavorite },
  productStore: {
    createProduct,
    createProductStatus,
    updateProduct,
    updateProductStatus,
  },
  onClose,
}) => {
  const classes = useStyle();
  const isEditMode = !!favorite.productId;
  const [description, setDescription] = useState(favorite.description); // required if new product
  const [price, setPrice] = useState(favorite.price); // optionnal
  const [pictureUrl, setPictureUrl] = useState(favorite.pictureUrl); // optionnal
  const [productUrl, setProductUrl] = useState(favorite.productUrl); // optionnal
  const [displayError, setDisplayError] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const onNewFavorite = async (e) => {
    setInProgress(true);
    // error state if none of the following fille : description OR favoriteSelected

    e.preventDefault();
    // if favorite selected, create purchase from favorite selected.

    if (!description)
      // and no favorite selected
      return setDisplayError(true);

    // else create product, then create purchase
    const product = await createProduct({
      description,
      price,
      pictureUrl,
      productUrl,
      taskId,
    });

    await createFavorite(product, taskId);

    applyStrategyAndToggleInProgress(taskId);

    setInProgress(false);
    onClose();
  };

  const onEditFavorite = async (e) => {
    setInProgress(true);
    e.preventDefault();

    updateProduct(favorite.productId, {
      description,
      price,
      pictureUrl,
      productUrl,
    });

    addFavorite(taskId, favorite.id, {
      description,
      price,
      pictureUrl,
      productUrl,
    });

    setInProgress(false);
    onClose();
  };

  return (
    <form
      onSubmit={isEditMode ? onEditFavorite : onNewFavorite}
      className={classes.root}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h2">
            <FormattedMessage
              id={`favorite.title.${isEditMode ? "edit" : "add"}`}
            />
          </Typography>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6} sm="auto">
            <PictureField
              className={classes.subfield}
              onChange={(url) => setPictureUrl(url)}
              value={pictureUrl}
            />
          </Grid>
          <Grid item xs={6} sm="auto">
            <LinkField
              value={productUrl}
              onChange={(url) => setProductUrl(url)}
              className={classes.subfield}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextField
              fullWidth
              variant="outlined"
              className={classes.subfield}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              label="Prix"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              inputProps={{
                min: 0,
                step: "0.01",
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            helperText={
              displayError &&
              "Vous devez indiquer au moins un mot de description"
            }
            label="Description de mon favori *"
            fullWidth
            variant="outlined"
            rows={4}
            error={displayError}
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        <Grid item container xs={12} justify="flex-end">
          {inProgress ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <Button size="large" onClick={onClose}>
                <FormattedMessage id="cta.cancel" />
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FormattedMessage
                  id={`purchase.title.${isEditMode ? "edit" : "add"}`}
                />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default inject(
  "favoriteStore",
  "productStore",
  "taskStore"
)(observer(CreateFavoriteStore));
