import { Grid, makeStyles, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import MotivationCount from "./MotivationCount";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 16,
    backgroundColor: theme.palette.primary.ultraLight,
    width: "100%",
  },
  motivationCount: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
    },
  },
}));

const TimeHeader = ({ timeGroupId, babyStore: { currentBaby } }) => {
  const classes = useStyle();

  const fDate = currentBaby.birthDate || currentBaby.expectedBirthDate;

  // TODO : computed value inside baby store
  const timeGroupDisplayedDate = {
    2:
      currentBaby.expectedBirthDate &&
      moment(currentBaby.expectedBirthDate).subtract(1, "months").format("LL"),
    3: moment(fDate).add(1, "months").format("LL"),
    4: moment(fDate).add(3, "months").format("LL"),
    6: moment(fDate).add(6, "months").format("LL"),
    7: moment(fDate).add(9, "months").format("LL"),
    16: moment(fDate).add(1, "years").format("YYYY"),
    8: moment(fDate).add(12, "months").format("LL"),
    9: moment(fDate).add(18, "months").format("LL"),
    12: moment(fDate).add(1, "years").format("YYYY"),
    13: moment(fDate).add(1, "years").format("YYYY"),
    14: moment(fDate).add(1, "years").format("YYYY"),
    15: moment(fDate).add(1, "years").format("YYYY"),
    10: moment(fDate).add(24, "months").format("LL"),
    11: moment(fDate).add(30, "months").format("LL"),
    17: moment(fDate).add(2, "years").format("YYYY"),
    18: moment(fDate).add(2, "years").format("YYYY"),
    19: moment(fDate).add(2, "years").format("YYYY"),
  };

  const shouldDisplayVariant =
    timeGroupId === 2 && !timeGroupDisplayedDate[timeGroupId];

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} sm={9}>
          <Typography variant="h2">
            {/* TODO : Save labels by timeGroupId not by order. Order can changes. */}
            <FormattedMessage
              id={`timeGroup.label.${timeGroupId}${
                shouldDisplayVariant ? "-variant" : ""
              }`}
              values={{
                fullDate: timeGroupDisplayedDate[timeGroupId],
                year: timeGroupDisplayedDate[timeGroupId],
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.motivationCount}>
          <MotivationCount timeGroupId={timeGroupId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default inject("babyStore")(observer(TimeHeader));
