import {
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import ReactGA from "react-ga";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentDateUtils from "@date-io/moment";

import HighlightedButton from "../components/buttons/HighlightedButton";
import { useSnackbar } from "notistack";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "16px 0",
    "& > div": {
      margin: "16px 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: 16,
      margin: 0,
      "& > div": {
        margin: "8px 0",
      },
    },
  },
  alignRight: {
    textAlign: "right",
  },
  main: {
    backgroundColor: "white",
    flexWrap: "unset",
  },
  mainItem: {
    padding: "16px 32px",
    display: "flex",
    borderBottom: "1px solid #EEEFF2",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "8px 16px",
      justifyContent: "space-between",
    },
  },
  label: {
    marginRight: 16,
  },
  birthMode: {
    backgroundColor: theme.palette.secondary.light,
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const AccountPage = ({
  authStore: { logout },
  userStore: { currentUser },
  babyStore: { currentBaby, updateCurrentBaby },
  intl: { formatMessage },
}) => {
  const classes = useStyle();
  const [isBirthMode, setIsBirthMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const onBirth = async (e) => {
    await updateCurrentBaby({ birthDate: selectedDate });

    // snackbar with cancel action
    enqueueSnackbar(formatMessage({ id: `account.birth.congrats` }), {
      variant: "success",
      action: (key) => (
        <Button
          style={{ color: "white" }}
          size="large"
          variant="text"
          onClick={() => {
            updateCurrentBaby({ birthDate: null });
            closeSnackbar(key);
          }}
        >
          <FormattedMessage id="cta.cancel" />
        </Button>
      ),
    });
  };

  return (
    <Grid
      container
      className={classes.root}
      // direction="column"
    >
      <Grid item xs={12} className={classes.alignRight}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={logout}
        >
          <FormattedMessage id="account.logout.cta" />
        </Button>
      </Grid>
      <Grid item xs={12} container direction="column" className={classes.main}>
        <Grid item xs={12} className={classes.mainItem}>
          <Typography variant="h3" className={classes.label}>
            <FormattedMessage id="account.email.label" />
          </Typography>
          <Typography variant="body1">{currentUser.email}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainItem}>
          <Typography variant="h3" className={classes.label}>
            <FormattedMessage id="account.register.label" />
          </Typography>

          <Typography variant="body1">
            {moment(currentUser.created).format("L")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainItem}>
          {currentBaby.birthDate ? (
            <>
              <Typography variant="h3" className={classes.label}>
                <FormattedMessage id="account.birthDate.label" />
              </Typography>

              <Typography variant="body1">
                {moment(currentBaby.birthDate).format("L")}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h3" className={classes.label}>
                <FormattedMessage id="account.expectedBirthDate.label" />
              </Typography>

              <Typography variant="body1">
                {moment(currentBaby.expectedBirthDate).format("L")}
              </Typography>
            </>
          )}
        </Grid>
        {!currentBaby.birthDate && (
          <Grid
            item
            xs={12}
            className={`${classes.mainItem} ${
              isBirthMode ? classes.birthMode : ""
            }`}
          >
            {isBirthMode ? (
              <>
                <Grid item container xs={12} justify="space-between">
                  <Grid item xs={12} md>
                    <Typography
                      variant="h3"
                      className={classes.label}
                      align={isMobile ? "center" : "left"}
                    >
                      <FormattedHTMLMessage id="account.birth.label" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md>
                    <MuiPickersUtilsProvider utils={MomentDateUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        disableToolbar
                        size={isMobile ? "small" : "medium"}
                        variant="inline"
                        inputVariant="outlined"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Sélectionner une date"
                        required
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md
                    container
                    justify={isMobile ? "center" : "flex-end"}
                  >
                    <Button
                      variant="text"
                      size="large"
                      onClick={() => setIsBirthMode(false)}
                    >
                      <FormattedMessage id="cta.cancel" />
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={onBirth}
                      disabled={!selectedDate}
                    >
                      <FormattedMessage id="cta.confirm" />
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <HighlightedButton onClick={() => setIsBirthMode(true)}>
                <FormattedMessage id="account.birth.cta" />
              </HighlightedButton>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <FormattedHTMLMessage id="account.info.text" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(
  inject("authStore", "userStore", "babyStore")(observer(AccountPage))
);
