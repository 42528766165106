import { runInAction, makeAutoObservable } from "mobx";
import { keyBy } from "lodash/fp";
import agent from "../agent";
import { computedFn } from "mobx-utils";
import { debounce, groupBy, isEmpty } from "lodash";
import FlexSearch from "flexsearch";
import { IDLE, LOADING, ERROR, SUCCESS } from "../asyncStatus";

const FILTERS_TYPES = [
  "status",
  "budget",
  "seasons",
  "size",
  "categoryId",
  "usefulness",
  "inProgress",
];

const ARRAY_FIELDS = ["size", "seasons"];

// todo : don't forget to add wanted properties for search (like synonym)
const index = new FlexSearch({
  tokenize: "full",
  encode: "advanced",
  doc: {
    id: "id",
    field: ["name", "synonyms", "categoryName", "categoryId", "inProgress"],
  },
});

export const TASK_TYPES = {
  toFetch: "A se procurer",
  admin: "Administrative",
  selectModel: "Choix du modèle",
  selectSolution: "Choix de la solution",
  master: "Tâche maitresse",
};

let logTimeoutCb;

class TaskStore {
  tasksByIds = {};

  // Current filters applied to compute search results
  filters = [{ type: "status", value: "todo" }];

  // Search field current input
  searchQuery = null;

  // Methods STATUS
  fetchCurrentBabyTasksStatus = IDLE;
  updateTaskStatus = IDLE;
  createTaskStatus = IDLE;

  // Task which is open in checklist / suitcase
  openTaskId = null;

  constructor(babyStore, purchaseStore, favoriteStore) {
    makeAutoObservable(this);
    this.babyStore = babyStore;
    this.purchaseStore = purchaseStore;
    this.favoriteStore = favoriteStore;
  }

  setOpenTaskId = (id) => {
    this.openTaskId = id;
  }

  setSearchQuery = debounce((val) => {
    this.searchQuery = val;

    try {
      if (!isEmpty(val)) {
        logTimeoutCb && clearTimeout(logTimeoutCb);
        logTimeoutCb = setTimeout(() => {
          agent.Event.create({
            type: "search",
            value: this.searchQuery,
          });
        }, 3000);
      }
    } catch (e) {
      console.error(e);
    }
  }, 300);

  /**
   * @description Apply current filters on tasks.
   * If there are several filters with same "type", it makes an "OR" for these same "type" filters.
   * Between two differents "type" filters, it makes an "AND".
   * If there is a query in progress, use flexsearch to find matching tasks.
   * @param ignoreStatus Boolean: IF true, it ignores the "status" filters.
   * @param noSubTasks Boolean: IF true, it ignores the tasks which have a "masterTaskId" set.
   */
  filteredTasks = computedFn(({ ignoreStatus, noSubTasks, isSuitcase }) => {
    let filteredTasks = Object.values(this.tasksByIds).filter((task) => {
      let isMatching = true;
      const filtersByType = groupBy(this.filters, (f) => f.type);

      if (ignoreStatus) delete filtersByType["status"];

      // exclude sub tasks if property set
      if (noSubTasks && task.masterStaticTaskId) isMatching = false;
      else {
        Object.keys(filtersByType).forEach((filterType) => {
          if (ARRAY_FIELDS.includes(filterType)) {
            if (
              !filtersByType[filterType].some((f) =>
                (task[filterType] || []).includes(f.value)
              )
            )
              isMatching = false;
          } else {
            if (
              !filtersByType[filterType].some(
                (f) => f.value === task[filterType]
              )
            )
              isMatching = false;
          }
        });
      }

      return isMatching;
    });

    if (this.searchQuery && this.searchQuery.length > 0) {
      const queryA =
        index.search({
          query: this.searchQuery,
          field: ["categoryName", "name"], // Doesn't work as expected with more than 2 fields.
          bool: "or",
        }) || [];

      const queryB =
        index.search({
          query: this.searchQuery,
          field: ["synonyms"], // Doesn't work as expected with more than 2 fields.
          bool: "or",
        }) || [];

      filteredTasks = filteredTasks.filter((filteredTask) =>
        queryA.concat(queryB).some(({ id }) => id === filteredTask.id)
      );
    }

    filteredTasks = filteredTasks.filter((t) =>
      isSuitcase ? t.isSuitcase : !t.isSuitcase
    );

    return filteredTasks;
  });

  taskSubTasks = computedFn((masterStaticTaskId) => {
    return Object.values(this.tasksByIds).filter(
      (task) => task.masterStaticTaskId === masterStaticTaskId
    );
  });

  isFilterSelected = computedFn((type, value) => {
    return this.filters.some(
      (filter) => filter.type === type && filter.value === value
    );
  });

  getFilteredTasksStatusCount = computedFn((statusValue, isSuitcase) => {
    return this.filteredTasks({
      // Sub tasks are displayed inside deleted tasks view.
      noSubTasks: true,
      ignoreStatus: true,
      isSuitcase,
    }).filter((task) => task.status === statusValue).length;
  });

  timeGroupStats = computedFn((timeGroupId) => {
    try {
      const timeGroupTasks = (
        groupBy(Object.values(this.tasksByIds), "timeGroupId")[timeGroupId] ||
        []
      ).filter((t) => !t.masterStaticTaskId);

      return {
        treated: timeGroupTasks.filter((t) => t.status !== "todo").length,
        total: timeGroupTasks.length,
      };
    } catch (e) {
      console.error(e);
      return {
        treated: 0,
        total: 0,
      };
    }
  });

  // TODO : Bad way to fetch categories ...
  tasksCategories = computedFn(({ isSuitcase }) => {
    const tasks = Object.values(this.tasksByIds).filter((t) =>
      isSuitcase ? t.isSuitcase : !t.isSuitcase
    );
    const categories = [];

    tasks.forEach(
      ({ categoryId, categoryName, categoryPictureUrl, categoryOrder }) => {
        if (!categories.some(({ id }) => categoryId === id))
          categories.push({
            id: categoryId,
            name: categoryName,
            pictureUrl: categoryPictureUrl,
            ordre: categoryOrder,
          });
      }
    );

    return categories;
  });

  get currentStatusFilter() {
    return this.filters.find((f) => f.type === "status");
  }

  getMasterTask = computedFn((taskId) => {
    try {
      return Object.values(this.tasksByIds).find(
        (t) => t.staticTaskId === this.tasksByIds[taskId].masterStaticTaskId
      );
    } catch (e) {
      return null;
    }
  });

  resetFilters = () => {
    this.filters = [{ type: "status", value: "todo" }];
  };

  /**
   * @description Add filter to filters. Status filter is unique, so it replaces it.
   */
  addFilter = (type, value) => {
    if (!FILTERS_TYPES.includes(type)) return false;

    if (type === "status") {
      this.filters[this.filters.findIndex((f) => f.type === type)] = {
        type,
        value,
      };
      this.filters = [...this.filters]; // trigger observable
    } else {
      // TODO : check if observable is triggered, else, performs concat()
      this.filters.push({ type, value });
    }
    return true;
  };

  removeFilter = (type, value) => {
    this.filters = this.filters.filter(
      (filter) => !(filter.type === type && filter.value === value)
    );
  };

  applyStrategyAndToggleInProgress = async (taskId) => {
    const task = this.tasksByIds[taskId];

    const linkedTask = Object.values(this.tasksByIds).find(
      (t) =>
        (t.linkedStaticTaskId === task.staticTaskId ||
          t.staticTaskId === task.linkedStaticTaskId) &&
        (task.isSuitcase ? !t.isSuitcase : t.isSuitcase)
    );

    this.toggleInProgress(task.id);
    linkedTask && this.toggleInProgress(linkedTask.id);
  };

  // TODO : Don't optimize "if" now.
  /**
   * @description Manage "inProgress" tag depends on tasks states.
   * @param {String} taskId
   */
  toggleInProgress = async (taskId) => {
    try {
      const task = this.tasksByIds[taskId];

      if (!task) return;

      switch (task.type) {
        case TASK_TYPES.master:
          if (
            this.taskSubTasks(task.staticTaskId).some(
              (t) => t.inProgress || t.status !== "todo"
            ) &&
            !task.inProgress
          ) {
            await this.updateTask(taskId, { inProgress: true });
          } else if (
            !this.taskSubTasks(task.staticTaskId).some(
              (t) => t.inProgress || t.status !== "todo"
            ) &&
            task.inProgress
          ) {
            await this.updateTask(taskId, { inProgress: false });
          }
          break;
        default:
          if (
            ((this.purchaseStore.taskPurchases(taskId) || []).length > 0 ||
              (this.favoriteStore.taskFavorites(taskId) || []).length > 0 ||
              !isEmpty(task.notes)) &&
            !task.inProgress &&
            task.status === "todo"
          ) {
            await this.updateTask(taskId, { inProgress: true });
          } else if (
            (this.purchaseStore.taskPurchases(taskId) || []).length === 0 &&
            (this.favoriteStore.taskFavorites(taskId) || []).length === 0 &&
            isEmpty(task.notes) &&
            task.inProgress
          ) {
            await this.updateTask(taskId, { inProgress: false });
          }
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCurrentBabyTasks = async () => {
    try {
      this.fetchCurrentBabyTasksStatus = LOADING;

      const { data: tasks } = await agent.Task.getByBabyId(
        this.babyStore.currentBaby.id
      );

      runInAction(() => {
        this.tasksByIds = keyBy("id", tasks);
        index.add(
          Object.values(this.tasksByIds).map((task) => ({
            id: task.id,
            name: task.name,
            synonyms: task.synonyms,
            categoryName: task.categoryName,
            categoryId: task.categoryId,
            inProgress: task.inProgress,
          }))
        );
        this.fetchCurrentBabyTasksStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchCurrentBabyTasksStatus = ERROR;
      });
    }
  };

  /**
   * @description Async method to update one task
   * @param taskId
   * @param taskToUpdate
   */
  updateTask = async (taskId, taskToUpdate, withToggle) => {
    try {
      this.updateTaskStatus = LOADING;

      const { data: tasksUpdated } = await agent.Task.update(
        taskId,
        taskToUpdate
      );

      runInAction(() => {
        tasksUpdated.forEach((t) => {
          this.tasksByIds[t.id] = t;
          index.add({
            id: t.id,
            name: t.name,
            synonyms: t.synonyms,
            categoryName: t.categoryName,
            categoryId: t.categoryId,
            inProgress: t.inProgress,
          });
        });

        this.updateTaskStatus = SUCCESS;

        setTimeout(() => {
          this.updateTaskStatus = IDLE;
        }, 2000);
      });
      if (withToggle) {
        await this.applyStrategyAndToggleInProgress(taskId);
        this.getMasterTask(taskId) &&
          (await this.applyStrategyAndToggleInProgress(
            this.getMasterTask(taskId).id
          ));
      }
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.updateTaskStatus = ERROR;
      });
    }
  };

  createTask = async (taskToCreate) => {
    try {
      this.createTaskStatus = LOADING;

      const { data: taskCreated } = await agent.Task.create(taskToCreate);

      runInAction(() => {
        this.tasksByIds = {
          ...this.tasksByIds,
          [taskCreated.id]: taskCreated,
        };

        // missing data
        index.add({
          id: taskCreated.id,
          name: taskCreated.name,
          synonyms: taskCreated.synonyms,
          categoryName: taskCreated.categoryName,
          inProgress: taskCreated.inProgress,
          categoryId: taskCreated.categoryId,
        });

        this.createTaskStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.createTaskStatus = ERROR;
      });
    }
  };
}

export default TaskStore;
