import { Container } from "@material-ui/core";
import React from "react";
import { SnackbarProvider } from "notistack";

import MainMenu from "../components/header/MainMenu";
import useStyle from "./Layout.css";
import ClapIcon from "../resources/icons/clap.svg";
import GarbageIcon from "../resources/icons/garbage.svg";
import Footer from "../components/footer/Footer";

const Layout = ({ children }) => {
  const classes = useStyle();
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      iconVariant={{
        success: <img src={ClapIcon} alt="Félicitations" />,
        warning: <img src={GarbageIcon} alt="Félicitations" />,
      }}
      classes={{
        variantSuccess: classes.snackbar,
        variantError: classes.snackbar,
        variantInfo: classes.snackbar,
        variantWarning: classes.snackbar,
      }}
    >
      <div>
        <MainMenu />

        <Container
          maxWidth="lg"
          disableGutters
          className={classes.mainContainer}
        >
          {children}
        </Container>

        {/* FOOTER */}
        <Footer />
      </div>
    </SnackbarProvider>
  );
};

export default Layout;
