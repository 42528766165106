import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

import useStyle from "./Auth.css";
import { FormattedMessage } from "react-intl";
import HighlightedButton from "../buttons/HighlightedButton";
import { Link } from "react-router-dom";

const Welcome = () => {
  const classes = useStyle();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Grid
      container
      className={`${classes.rootContainer} ${classes.root}`}
      alignItems="center"
    >
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            <FormattedMessage id="welcome.title" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            component={Link}
            to={"/signin"}
          >
            <FormattedMessage id="menu.signin.cta" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <HighlightedButton fullWidth component={Link} to={"/signup"}>
            <FormattedMessage id="menu.signup.cta" />
          </HighlightedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Welcome;
