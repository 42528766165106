import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Popover,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { isEmpty, orderBy } from "lodash";

import HighlightedButton from "../../buttons/HighlightedButton";

const useStyle = makeStyles((theme) => ({
  btn: {
    margin: 8,
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  counter: {
    marginLeft: 8,
    fontSize: "0.8rem",
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
    padding: "0 7px",
  },
}));

const ChecklistFilter = ({
  taskStore: {
    addFilter,
    removeFilter,
    isFilterSelected,
    tasksCategories,
    resetFilters,
    filters,
  },
  intl: { formatMessage },
  isSuitcase,
}) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterChange = (type, value) => (e) => {
    e.target.checked ? addFilter(type, value) : removeFilter(type, value);
  };

  const isChecked = (type, value) => {
    return isFilterSelected(type, value);
  };

  return (
    <>
      <HighlightedButton
        onClick={toggleDropdown}
        className={classes.btn}
        startIcon={<i className={`fas fa-filter ${classes.icon}`}></i>}
      >
        <FormattedMessage id="filter.cta" />
        <div className={classes.counter}>{filters.length - 1}</div>
      </HighlightedButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={toggleDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 16,
          },
        }}
      >
        <Grid container spacing={2} direction="column">
          {/* in progress filter */}
          <Grid item>
            {}
            <HighlightedButton
              fullWidth
              disabled={filters.length < 2}
              onClick={resetFilters}
            >
              <FormattedMessage id="filter.label.reset" />
            </HighlightedButton>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="filter.label.inProgress" />
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("inProgress", true)}
                      onChange={handleFilterChange("inProgress", true)}
                      name="inProgress"
                    />
                  }
                  label={formatMessage({
                    id: "filter.label.inProgress.processing",
                  })}
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {/* Usefulness filters */}
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="filter.label.usefulness" />
              </FormLabel>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 5)}
                      onChange={handleFilterChange("usefulness", 5)}
                      name="usefulness-5"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.5" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 4)}
                      onChange={handleFilterChange("usefulness", 4)}
                      name="usefulness-4"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.4" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 3)}
                      onChange={handleFilterChange("usefulness", 3)}
                      name="usefulness-3"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.3" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 2)}
                      onChange={handleFilterChange("usefulness", 2)}
                      name="usefulness-2"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.2" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 1)}
                      onChange={handleFilterChange("usefulness", 1)}
                      name="usefulness-51"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.1" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked("usefulness", 0)}
                      onChange={handleFilterChange("usefulness", 0)}
                      name="usefulness-0"
                    />
                  }
                  label={formatMessage({ id: "filter.label.usefulness.0" })}
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {/* Category filters */}
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage id="filter.label.category" />
              </FormLabel>
              <FormGroup>
                {orderBy(tasksCategories({ isSuitcase }), [
                  "ordre",
                  "desc",
                ]).map(({ id, name }) => {
                  if (isEmpty(name)) return null;
                  return (
                    <FormControlLabel
                      key={name + id}
                      control={
                        <Checkbox
                          checked={isChecked("categoryId", id)}
                          onChange={handleFilterChange("categoryId", id)}
                          name={`categoryId-${id}`}
                        />
                      }
                      label={name}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Grid>

          {!isSuitcase && (
            <>
              {/* Budget filters */}
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <FormattedMessage id="filter.label.budget" />
                  </FormLabel>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("budget", 4)}
                          onChange={handleFilterChange("budget", 4)}
                          name="budget-4"
                        />
                      }
                      label={formatMessage({ id: "filter.label.budget.4" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("budget", 3)}
                          onChange={handleFilterChange("budget", 3)}
                          name="budget-3"
                        />
                      }
                      label={formatMessage({ id: "filter.label.budget.3" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("budget", 2)}
                          onChange={handleFilterChange("budget", 2)}
                          name="budget-2"
                        />
                      }
                      label={formatMessage({ id: "filter.label.budget.2" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("budget", 1)}
                          onChange={handleFilterChange("budget", 1)}
                          name="budget-1"
                        />
                      }
                      label={formatMessage({ id: "filter.label.budget.1" })}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* Season filters */}
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <FormattedMessage id="filter.label.season" />
                  </FormLabel>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("seasons", "Hiver")}
                          onChange={handleFilterChange("seasons", "Hiver")}
                          name="season-winter"
                        />
                      }
                      label={formatMessage({
                        id: "filter.label.season.winter",
                      })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("seasons", "Eté")}
                          onChange={handleFilterChange("seasons", "Eté")}
                          name="season-summer"
                        />
                      }
                      label={formatMessage({
                        id: "filter.label.season.summer",
                      })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("seasons", "Mi-Saison")}
                          onChange={handleFilterChange("seasons", "Mi-Saison")}
                          name="season-midseason"
                        />
                      }
                      label={formatMessage({
                        id: "filter.label.season.midseason",
                      })}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {/* Size filters */}
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <FormattedMessage id="filter.label.size" />
                  </FormLabel>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "1 mois")}
                          onChange={handleFilterChange("size", "1 mois")}
                          name="size-1"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.1" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "3 mois")}
                          onChange={handleFilterChange("size", "3 mois")}
                          name="size-3"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.3" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "6 mois")}
                          onChange={handleFilterChange("size", "6 mois")}
                          name="size-6"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.6" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "9 mois")}
                          onChange={handleFilterChange("size", "9 mois")}
                          name="size-9"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.9" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "1 an")}
                          onChange={handleFilterChange("size", "1 an")}
                          name="size-12"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.12" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "18 mois")}
                          onChange={handleFilterChange("size", "18 mois")}
                          name="size-18"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.18" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked("size", "2 ans")}
                          onChange={handleFilterChange("size", "2 ans")}
                          name="size-24"
                        />
                      }
                      label={formatMessage({ id: "filter.label.size.24" })}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Popover>
    </>
  );
};

export default injectIntl(inject("taskStore")(observer(ChecklistFilter)));
