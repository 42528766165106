import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 25,
    "& span": {
      color: "white !important",
    },
  },
}));

const RounedButton = ({ children, ...props }) => {
  const classes = useStyle();
  return (
    <Button variant="text" size="large" className={classes.root} {...props}>
      {children}
    </Button>
  );
};

export default RounedButton;
