import { runInAction, makeAutoObservable } from "mobx";
import agent from "../agent";
import { IDLE, LOADING, ERROR, SUCCESS } from "../asyncStatus";
import babyStore from "./baby.store";

class UserStore {
  currentUser = null;
  fetchCurrentUserStatus = IDLE;
  fetchCurrentUserDataStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description Fetch current user data (user, baby, checklist) at App load.
   * This method can last a long time as it's
   */
  fetchCurrentUserData = async () => {
    try {
      this.fetchCurrentUserDataStatus = LOADING;

      await this.fetchCurrentUser();
      await babyStore.fetchCurrentBaby();

      // fetch checklist

      runInAction(() => {
        this.fetchCurrentUserDataStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchCurrentUserDataStatus = ERROR;
      });
    }
  };

  fetchCurrentUser = async () => {
    try {
      this.fetchCurrentUserStatus = LOADING;

      const { data: currentUserP } = await agent.User.me();

      runInAction(() => {
        this.currentUser = currentUserP;
        this.fetchCurrentUserStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchCurrentUserStatus = ERROR;
      });
    }
  };
}

export default new UserStore();
