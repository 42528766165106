import React, { useState } from "react";
import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { LOADING, SUCCESS } from "../../../../../asyncStatus";

const NotesView = ({
  taskId,
  taskStore: { tasksByIds, updateTask, updateTaskStatus },
}) => {
  const task = tasksByIds[taskId];
  const [notes, setNotes] = useState(task.notes);

  const save = async () => {
    updateTask(taskId, { notes }, true);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <TextField
          multiline
          placeholder="Mes notes ..."
          fullWidth
          variant="outlined"
          rows={6}
          value={notes || ""}
          onChange={(e) => setNotes(e.target.value)}
          onBlur={save}
        />
      </Grid>
      <Grid item>
        {updateTaskStatus === LOADING ? (
          <CircularProgress color="secondary" />
        ) : updateTaskStatus === SUCCESS ? (
          <Typography variant="body1">
            Vos notes ont été enregistrées avec succès.
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default inject("taskStore")(observer(NotesView));
