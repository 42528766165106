import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 4,
    width: "100%",
    padding: 16,
  },
  icon: {
    opacity: 0.5,
    color: theme.palette.primary.main,
  },
  input: {
    outline: "none",
    border: "none",
    margin: "0 16px",
    // display: "flex",
    // flexGrow: 1,
    width: "calc(100% - 16px)",
    height: "100%",
    padding: 0,
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: "1.75",
    fontFamily: "Open Sans",
    opacity: 0.5,
    color: theme.palette.primary.main,
  },
}));
