import { runInAction, makeAutoObservable } from "mobx";
import agent from "../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../asyncStatus";

class CategoryStore {
  categories = [];
  fetchCategoriesStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  fetchCategories = async (staticTaskId) => {
    try {
      this.fetchCategoriesStatus = LOADING;

      const { data: categories } = await agent.Category.get();

      runInAction(() => {
        this.categories = categories;
        this.fetchCategoriesStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchCategoriesStatus = ERROR;
      });
    }
  };
}

export default new CategoryStore();
