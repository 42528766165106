import React, { useState, useEffect } from "react";
import { Grid, Container, CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Redirect, Switch, Route } from "react-router-dom";
import { compose } from "lodash/fp";
import ChecklistPage from "../pages/Checklist.page";
import AccountPage from "../pages/Account.page";
import SuitcasePage from "../pages/Suitcase.page";

const Loader = () => (
  <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <CircularProgress color="secondary" />
  </div>
);

const Skeleton = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Container
      maxWidth="lg"
      disableGutters
      style={{ padding: 16, display: "flex" }}
    >
      <Grid container spacing={4} style={{ position: "relative" }}>
        <Switch>
          <Route path={`/checklist`}>
            <ChecklistPage />
          </Route>
          <Route path={`/suitcase`}>
            <SuitcasePage />
          </Route>
          <Route path={`/account`}>
            <AccountPage />
          </Route>

          <Route path="/">
            <Redirect push to={`/checklist`} />
          </Route>
        </Switch>
      </Grid>

      {/* Si affichage des actions en mode popup, faire comme ça, sinon délcarer les routes dans le composant Checklist par exemple */}
      <Switch>{/* ACTIONS ROUTES */}</Switch>
    </Container>
  );
};

const AppWrapper = ({
  authStore: { isAuth },
  userStore: { fetchCurrentUserData },
  commonStore: { getRefreshToken },
}) => {
  const [isUserLoadDone, setIsUserLoadDone] = useState(false);

  // Fetch User and data (user, baby, role, checklists?)
  useEffect(() => {
    const fetchUser = async () => {
      await fetchCurrentUserData();
      setIsUserLoadDone(true);
    };

    const compareUsersOnFocus = () => {
      const initRefreshToken = getRefreshToken();
      document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState == "visible") {
          const currentRefreshToken = getRefreshToken();

          if (initRefreshToken !== currentRefreshToken)
            window.location.href = window.location.href + "?reloaded=true";
        }
      });
    };

    isAuth() ? fetchUser() : setIsUserLoadDone(true);
    isAuth() && compareUsersOnFocus();
  }, [isAuth]);

  if (!isUserLoadDone) return <Loader />;

  return (
    <Switch>
      <Route path="/">
        {!isAuth() ? <Redirect push to={`/welcome`} /> : <Skeleton />}
      </Route>
    </Switch>
  );
};

export default compose(
  inject("authStore", "userStore", "commonStore"),
  observer
)(AppWrapper);
