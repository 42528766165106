import { Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { injectIntl } from "react-intl";

import ActionCard from "../../../utils/ActionCard";
import { TASK_TYPES } from "../../../../stores/task.store";
import { useSnackbar } from "notistack";
import { LOADING } from "../../../../asyncStatus";

const ModelChoices = ({
  taskStore: { updateTask, tasksByIds, updateTaskStatus: currentRequestStatus },
  taskId,
  updateTaskStatus,
  intl: { formatMessage },
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const task = tasksByIds[taskId];
  const choicesArray = task ? task.choices.split(";") : [];

  const onChoiceSelect = (choiceName) => (e) => {
    if (currentRequestStatus !== LOADING)
      updateTask(taskId, { status: "done", selectedChoice: choiceName }, true);

    enqueueSnackbar(formatMessage({ id: `snackbar.label.tasksAdded` }), {
      variant: "success",
      action: (key) => <></>,
    });
  };

  console.log(task.type);

  return (
    <Grid container direction="row" spacing={2} justify="center">
      {task.type === TASK_TYPES.selectModel && (
        <Grid item xs={12} sm={6} md={3}>
          <ActionCard
            label={formatMessage({ id: "task.action.done.label" })}
            ctaLabel={formatMessage({ id: "task.action.done.cta.label" })}
            onCtaClick={updateTaskStatus("done")}
          />
        </Grid>
      )}
      {choicesArray.map((choice) => (
        <Grid item key={choice} xs={12} sm={6} md={3}>
          <ActionCard
            label={choice}
            ctaLabel={formatMessage({
              id: "task.action.model.cta.label." + task.type,
            })}
            onCtaClick={onChoiceSelect(choice.trim())}
          />
        </Grid>
      ))}
      {task.type === TASK_TYPES.selectModel && (
        <Grid item xs={12} sm={6} md={3}>
          <ActionCard
            ctaIcon={<DeleteIcon color="secondary" />}
            label={formatMessage({ id: "task.action.delete.label" })}
            ctaLabel={formatMessage({ id: "task.action.delete.cta.label" })}
            onCtaClick={updateTaskStatus("deleted")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(inject("taskStore")(observer(ModelChoices)));
