import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    boxShadow: "initial",
  },
  fullHeight: {
    // height: "100%"
  },
  highlighted: {
    // backgroundColor: theme.palette.secondary.light,
  },
  headerLogoHeight: {
    height: 45,
    [theme.breakpoints.down("xs")]: {
      height: 40,
    },
  },
  activeLink: {
    borderBottom: "3px solid",
    borderBottomColor: theme.palette.secondary.main + " !important",
  },
  drawerRoot: {
    width: 250,
    position: "relative",
    textAlign: "right",
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    "& span": {
      color: "white !important",
      fontWeight: 600,
    },
  },
  container: {
    padding: "0 32px",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px",
    },
  },
  roundBtn: {
    borderRadius: 25,
    border: "1px solid white",
  },
  desktopMenuBtn: {
    borderBottom: "3px solid",
    borderBottomColor: "white",
    margin: 8,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    borderRadius: 0,
  },
}));
