import { Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Checklist from "../components/checklist/main/Checklist";
import ChecklistFiltersBar from "../components/checklist/main/ChecklistFiltersBar";
import MainAppHeader from "../components/header/MainAppHeader";
import { subscribeUser } from "../subscription";

const ChecklistPage = ({ commonStore: { beforeInstallPromptEvent } }) => {
  const showInstallPrompt = () => {
    console.log("Show install prompt");
    try {
      window.removeEventListener("scroll", showInstallPrompt);
      beforeInstallPromptEvent.prompt();
      beforeInstallPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // TODO : Do not call it every times ...
    // window.addEventListener("scroll", showInstallPrompt);
    ReactGA.pageview(window.location.pathname);
    subscribeUser();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MainAppHeader />
      </Grid>
      <Grid item xs={12}>
        <ChecklistFiltersBar />
      </Grid>
      <Grid item xs={12}>
        <Checklist />
      </Grid>
    </Grid>
  );
};

export default inject("commonStore")(observer(ChecklistPage));
