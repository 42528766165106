import React, { useRef, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import ActionCard from "../../../../utils/ActionCard";
import CreateFavoriteForm from "./CreateFavoriteForm";
import { inject, observer } from "mobx-react";
import ProductCard from "../../../../utils/ProductCard";
import FavoriteDefaultPicture from "../../../../../resources/images/favorite_default.png";
import { useSnackbar } from "notistack";
import { FormattedMessage, injectIntl } from "react-intl";

const useStyle = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.secondary.light,
    height: 36,
    width: 36,
  },
  purchaseBtn: {
    marginRight: 8,
    marginTop: 8,
  },
}));

const scrollToTaskRow = (elt) => {
  console.log("scrollToTaskRow");
  const yOffset = -58;
  const y = elt.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: "smooth" });
};

const scrollToEnd = (elt) => {
  console.log("scrollToEnd");
  elt.scrollIntoView({
    behavior: "smooth",
    block: "end",
  });
};

const FavoritesView = ({
  taskId,
  favoriteStore: {
    taskFavorites,
    deleteFavorite,
    updateFavorite,
    updateFavoriteStatus,
    addFavorite,
    removeFavorite,
  },
  purchaseStore: { createPurchase, createPurchaseStatus },
  taskStore: { applyStrategyAndToggleInProgress },
  intl: { formatMessage },
}) => {
  const classes = useStyle();
  const itemRef = useRef();
  const [isCreateMode, setIsCreateMode] = useState();
  const [favoriteToEdit, setFavoriteToEdit] = useState(null);
  const [gotItInProgress, setGotItInProgress] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleIGotIt = async (favId, fav) => {
    setGotItInProgress(true);
    await updateFavorite(favId, taskId, { purchased: true });
    await createPurchase(
      {
        id: fav.productId,
        description: fav.description,
        price: fav.price,
        pictureUrl: fav.pictureUrl,
        productUrl: fav.productUrl,
        brand: fav.brand,
      },
      taskId
    );
    setGotItInProgress(false);
  };

  const onDelete = async (favId, taskId) => {
    const favToDelete = taskFavorites(taskId).find((f) => f.id == favId);
    removeFavorite(taskId, favId);

    const timeout = setTimeout(async () => {
      await deleteFavorite(favId, taskId);
      applyStrategyAndToggleInProgress(taskId);
    }, 5000);

    enqueueSnackbar(formatMessage({ id: "snackbar.label.favorite.removed" }), {
      variant: "warning",
      action: (key) => (
        <Button
          style={{ color: "white" }}
          size="large"
          variant="text"
          onClick={() => {
            clearTimeout(timeout);
            addFavorite(taskId, favId, favToDelete);
            closeSnackbar(key);
          }}
        >
          <FormattedMessage id="cta.cancel" />
        </Button>
      ),
    });
  };

  const openCreate = () => {
    setIsCreateMode(true);
    scrollToTaskRow(itemRef.current);
  };

  const closeCreate = () => {
    setIsCreateMode(false);
    setTimeout(() => {
      isMobile && scrollToEnd(itemRef.current);
    }, 50);
  };

  return (
    <Grid container direction="row" ref={itemRef}>
      {isCreateMode ? (
        <CreateFavoriteForm taskId={taskId} onClose={closeCreate} />
      ) : favoriteToEdit ? (
        <CreateFavoriteForm
          taskId={taskId}
          favorite={favoriteToEdit}
          onClose={() => setFavoriteToEdit(null)}
        />
      ) : (
        <Grid item container spacing={2}>
          {(taskFavorites(taskId) || []).map((fav) => (
            <Grid item key={fav.id} xs={12} sm={6} md={3}>
              <ProductCard
                title={fav.title || fav.description}
                achat={fav}
                price={fav.price}
                pictureUrl={fav.pictureUrl || FavoriteDefaultPicture}
                productUrl={fav.productUrl}
                onDelete={() => onDelete(fav.id, taskId)}
                onEdit={() => setFavoriteToEdit(fav)}
                FooterActions={
                  <>
                    {fav.productUrl && (
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        href={fav.productUrl}
                        target="_blank"
                        rel="nofollow"
                        className={classes.purchaseBtn}
                        size="small"
                      >
                        Acheter
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      className={classes.purchaseBtn}
                      onClick={() => handleIGotIt(fav.id, fav)}
                      disabled={gotItInProgress}
                    >
                      Je l'ai !
                    </Button>
                  </>
                }
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <ActionCard
              onClick={openCreate}
              label="J'en ai 1 !"
              height={326}
              iconButton={
                <IconButton
                  size="small"
                  color="secondary"
                  className={classes.btn}
                >
                  <i className="fas fa-plus"></i>
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(
  inject("favoriteStore", "purchaseStore", "taskStore")(observer(FavoritesView))
);
