import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import agent from "../../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../../asyncStatus";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  iconBtn: {
    flex: "0 0 auto",
    width: 58,
    height: 58,
    borderRadius: 58,
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.secondary.light,
    marginRight: 8,
    fontSize: "1.6rem",
  },
  text: {
    lineHeight: "1",
  },
  inputFile: {
    display: "none",
  },
}));

const PictureField = ({ value, onChange, className, ...other }) => {
  const classes = useStyle();
  const inputRef = useRef();
  const [loadStatus, setLoadStatus] = useState(IDLE);

  const uploadPicture = async (e) => {
    if (e.target.files.length === 0) return;
    try {
      setLoadStatus(LOADING);
      const { imageUrl } = await agent.Picture.create(e.target.files[0]);
      setLoadStatus(SUCCESS);
      onChange(imageUrl);
    } catch (e) {
      console.error(e);
      setLoadStatus(ERROR);
    }
  };

  return (
    <div
      className={`${classes.root} ${className}`}
      onClick={() => inputRef.current.click()}
      {...other}
    >
      <input
        ref={inputRef}
        type="file"
        name="image"
        onChange={uploadPicture}
        className={classes.inputFile}
      />
      {loadStatus === LOADING ? (
        <CircularProgress color="secondary" />
      ) : value ? (
        <div
          className={classes.iconBtn}
          style={{ backgroundImage: `url(${value})` }}
        ></div>
      ) : (
        <IconButton size="small" color="secondary" className={classes.iconBtn}>
          <i className="fas fa-camera"></i>
        </IconButton>
      )}

      <div>
        <Typography variant="caption" className={classes.text}>
          (optionnel)
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {value ? (
            <FormattedMessage id="task.product.picture.update" />
          ) : (
            <FormattedMessage id="task.product.picture.add" />
          )}
        </Typography>
      </div>
    </div>
  );
};

export default PictureField;
