import {
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import HighlightedButton from "../../buttons/HighlightedButton";
import ChecklistFilter from "./ChecklistFilter";

const useStyle = makeStyles((theme) => ({
  counter: {
    marginLeft: 8,
    fontSize: "0.8rem",
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
    padding: "0 7px",
  },
  mobileMenu: {
    padding: 0,
    boxShadow: "0px 16px 20px rgba(0, 0, 0, 0.136528) !important",
    "& ul": {
      padding: 0,
    },
  },
  mobileMenuItem: {
    padding: 0,
    minHeight: "initial",
  },
  fullWidth: {
    width: "100%",
  },
  arrow: {
    color: theme.palette.secondary.main,
    marginRight: -8,
    marginLeft: 8,
  },
}));

const ChecklistFiltersBar = ({
  taskStore: {
    addFilter,
    getFilteredTasksStatusCount,
    isFilterSelected,
    currentStatusFilter,
  },
  isSuitcase,
}) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const i18nSuffix = isSuitcase ? ".suitcase" : ".checklist";

  return (
    <Grid container spacing={2} justify="space-between">
      {isMobile ? (
        <>
          <Grid item>
            <HighlightedButton
              style={{ margin: 8 }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              highlighted={true}
            >
              <FormattedMessage
                id={`task.filter.status.${currentStatusFilter.value}${i18nSuffix}`}
              />
              <div className={classes.counter}>
                {getFilteredTasksStatusCount(
                  currentStatusFilter.value,
                  isSuitcase
                )}
              </div>
              <ArrowDropDownIcon className={classes.arrow} />
            </HighlightedButton>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            className={classes.mobileMenu}
          >
            <MenuItem className={classes.mobileMenuItem}>
              <HighlightedButton
                className={classes.fullWidth}
                onClick={() => addFilter("status", "todo")}
                highlighted={isFilterSelected("status", "todo")}
              >
                <FormattedMessage id={`task.filter.status.todo${i18nSuffix}`} />
                <div className={classes.counter}>
                  {getFilteredTasksStatusCount("todo", isSuitcase)}
                </div>
              </HighlightedButton>
            </MenuItem>
            <MenuItem className={classes.mobileMenuItem}>
              <HighlightedButton
                className={classes.fullWidth}
                onClick={() => addFilter("status", "done")}
                highlighted={isFilterSelected("status", "done")}
              >
                <FormattedMessage id={`task.filter.status.done${i18nSuffix}`} />
                <div className={classes.counter}>
                  {getFilteredTasksStatusCount("done", isSuitcase)}
                </div>
              </HighlightedButton>
            </MenuItem>
            <MenuItem className={classes.mobileMenuItem}>
              <HighlightedButton
                className={classes.fullWidth}
                onClick={() => addFilter("status", "deleted")}
                highlighted={isFilterSelected("status", "deleted")}
              >
                <FormattedMessage
                  id={`task.filter.status.deleted${i18nSuffix}`}
                />
                <div className={classes.counter}>
                  {getFilteredTasksStatusCount("deleted", isSuitcase)}
                </div>
              </HighlightedButton>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Grid item>
          <HighlightedButton
            style={{ margin: 8, marginLeft: 0 }}
            onClick={() => addFilter("status", "todo")}
            highlighted={isFilterSelected("status", "todo")}
          >
            <FormattedMessage id={`task.filter.status.todo${i18nSuffix}`} />
            <div className={classes.counter}>
              {getFilteredTasksStatusCount("todo", isSuitcase)}
            </div>
          </HighlightedButton>
          <HighlightedButton
            style={{ margin: 8 }}
            onClick={() => addFilter("status", "done")}
            highlighted={isFilterSelected("status", "done")}
          >
            <FormattedMessage id={`task.filter.status.done${i18nSuffix}`} />
            <div className={classes.counter}>
              {getFilteredTasksStatusCount("done", isSuitcase)}
            </div>
          </HighlightedButton>
          <HighlightedButton
            style={{ margin: 8 }}
            onClick={() => addFilter("status", "deleted")}
            highlighted={isFilterSelected("status", "deleted")}
          >
            <FormattedMessage id={`task.filter.status.deleted${i18nSuffix}`} />
            <div className={classes.counter}>
              {getFilteredTasksStatusCount("deleted", isSuitcase)}
            </div>
          </HighlightedButton>
        </Grid>
      )}

      <Grid item>
        <ChecklistFilter isSuitcase={isSuitcase} />
      </Grid>
    </Grid>
  );
};

export default inject("taskStore")(observer(ChecklistFiltersBar));
