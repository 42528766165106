import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  rootContainer: {
    width: "100%",
    height: "100%",
  },
  root: {
    height: "100%",
    padding: "64px 32px",
    [theme.breakpoints.down("md")]: {
      padding: "32px 16px",
    },
  },
  magicLinkSuccessImg: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
  },
}));
