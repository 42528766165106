import React, { Component } from "react";
import "intersection-observer";
import { BrowserRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { inject } from "mobx-react";
import LogRocket from "logrocket";
import Routes from "./routes/Routes";

smoothscroll.polyfill();

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("ma-baby-checklist/pwa");
}

try {
  if(process.env.NODE_ENV === "development" ) {
    document.title = "🚧 (DEV) Ma Baby Checklist";
  } else if (window.location.host.search("staging") !== -1) {
    document.title = "🚧 (STAGING) Ma Baby Checklist"
  }
}catch(e) {
  console.error(e);
}

class App extends Component {
  beforeInstallPromptEventListener = (beforeInstallPromptEvent) => {
    this.props.commonStore.setBeforeInstallPromptEvent(
      beforeInstallPromptEvent
    );

    if (
      window.location.pathname.includes("signin") ||
      window.location.pathname.includes("signup")
    ) {
      beforeInstallPromptEvent.preventDefault();
    }
  };

  componentDidMount() {
    window.addEventListener(
      "beforeinstallprompt",
      this.beforeInstallPromptEventListener
    );
  }

  componentWillUmount() {
    window.removeEventListener(
      "beforeinstallprompt",
      this.beforeInstallPromptEventListener
    );
  }

  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default inject("commonStore")(App);
