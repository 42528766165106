import { Grid, Hidden, Typography, useMediaQuery } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import moment from "moment";

import ImgEnceinte from "../../resources/images/cloud_enceinte.png";
import ImgNaissance from "../../resources/images/cloud_naissance.png";
import ImgEveil from "../../resources/images/cloud_eveil.png";
import ImgSuitcase from "../../resources/images/cloud_suitcase.png";
import SearchField from "../search/SearchField";
import useStyle from "./MainAppHeader.css";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

const MainAppHeader = ({
  babyStore: {
    currentBaby: { birthDate, expectedBirthDate },
  },
  userStore: {
    currentUser: { visitsCount },
  },
  isSuitcase,
}) => {
  const classes = useStyle();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const daysRemaining =
    moment(birthDate || expectedBirthDate).diff(moment(), "days") + 1;

  const monthsPast = moment().diff(
    moment(birthDate || expectedBirthDate),
    "months"
  );

  const subtitlePostPart =
    visitsCount === 1
      ? birthDate && !expectedBirthDate
        ? "afterBirth"
        : "beforeBirth"
      : `variant.${((visitsCount - 2) % 6) + 1}`;

  const ImgSrc = isSuitcase
    ? ImgSuitcase
    : daysRemaining > 0
    ? ImgEnceinte
    : monthsPast >= 6
    ? ImgEveil
    : ImgNaissance;
  return (
    <Grid container alignItems="center">
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          md={7}
          container
          alignItems="center"
          direction="column"
        >
          <img src={ImgSrc} alt="Cloud" className={classes.cloudImg} />
        </Grid>
      </Hidden>

      <Grid item xs={12} md={5} container>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justify={isMobile ? "center" : "flex-start"}
          className={classes.mainTitleItem}
        >
          {isSuitcase ? (
            <Typography variant="h1">
              <FormattedMessage id="header.suitcase.title" />
            </Typography>
          ) : (
            <>
              {daysRemaining >= 0 && (
                <div className={classes.bigCount}>J-{daysRemaining}</div>
              )}
              <Typography variant="h1">
                {daysRemaining >= 0 ? "avant l'happy day !" : `Déjà`}
              </Typography>
              {daysRemaining < 0 && (
                <div className={classes.bigCount} style={{ marginLeft: 8 }}>
                  {monthsPast > 0 ? (
                    <>{monthsPast} mois !!</>
                  ) : (
                    <>{-daysRemaining} jours !!</>
                  )}
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Typography variant="subtitle2" align={isMobile ? "center" : "left"}>
            {isSuitcase ? (
              <FormattedHTMLMessage id="header.subtitle.suitcase" />
            ) : (
              <FormattedMessage id={`header.subtitle.${subtitlePostPart}`} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <SearchField />
        </Grid>
      </Grid>

      <Hidden smDown>
        <Grid
          item
          xs={12}
          md={7}
          container
          alignItems="center"
          direction="column"
        >
          <img src={ImgSrc} alt="Cloud" className={classes.cloudImg} />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default inject("babyStore", "userStore")(observer(MainAppHeader));
