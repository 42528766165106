import { makeStyles, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      alignItems: "flex-start",
    },
  },
  text: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  sliderContainer: {
    marginTop: 8,
    width: "50%",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 9,
    overflow: "hidden",
    height: 5,
  },
  sliderValue: {
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const MotivationCount = ({ timeGroupId, taskStore: { timeGroupStats } }) => {
  const classes = useStyle();
  const { treated, total } = timeGroupStats(timeGroupId);

  const rangeLabel =
    treated > total - 3
      ? "range4"
      : treated > 3
      ? "range3"
      : treated > 0
      ? "range2"
      : "range1";

  const randomVariant = Math.floor(Math.random() * 2 + 1);

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.text}>
        <FormattedMessage
          id={`timeGroup.motivation.${rangeLabel}.variant${randomVariant}`}
          values={{
            treated: treated,
            total: total,
          }}
        />
      </Typography>
      <div className={classes.sliderContainer}>
        <div
          className={classes.sliderValue}
          style={{ width: Math.floor((treated / total) * 100) + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default inject("taskStore")(observer(MotivationCount));
