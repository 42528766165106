import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { get } from "lodash/fp";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import FavoriteDefaultPicture from "../../../../../resources/images/favorite_default.png"; // TODO : should be favorite default picture

const useStyle = makeStyles((theme) => ({
  cardRoot: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    padding: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  picture: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 64,
    height: 64,
    borderRadius: 32,
    overflow: "hidden",
    marginRight: 8,
  },
  text: {
    width: "calc(100% - 110px)",
  },
}));

const FavList = ({
  favoriteStore: { taskFavorites },
  taskId,
  selected,
  onSelect,
  onRemove,
}) => {
  const classes = useStyle();
  const favs = taskFavorites(taskId) || [];

  const isSelected = (favId) => {
    return selected.some((s) => s.id == favId);
  };

  return (
    <Grid container spacing={2}>
      {favs.map((fav) => (
        <Grid item xs={12} sm={6} md={3}>
          <div
            className={classes.cardRoot}
            onClick={() => (isSelected(fav.id) ? onRemove(fav) : onSelect(fav))}
          >
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
              checked={isSelected(fav.id)}
            />
            <div
              className={classes.picture}
              style={{
                backgroundImage: `url(${
                  fav.pictureUrl || FavoriteDefaultPicture
                })`,
              }}
            ></div>
            <div className={classes.text}>
              <Typography noWrap variant="body1">
                {fav.description}
              </Typography>
              <Typography noWrap variant="body2">
                {fav.marque}&nbsp;
              </Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default inject("favoriteStore")(observer(FavList));
