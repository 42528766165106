import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentDateUtils from "@date-io/moment";
import ReactGA from "react-ga";

import useStyle from "./Auth.css";
import RoundedButton from "../buttons/RoundedButton";
import { LOADING, SUCCESS } from "../../asyncStatus";
import SuccessImg from "../../resources/images/signin_success_magic_link.png";

const Signup = ({ authStore: { signup, signupStatus } }) => {
  const classes = useStyle();
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const onSubmit = (e) => {
    e.preventDefault();

    if (moment(selectedDate) < moment(new Date()).subtract(9, "months"))
      return setErrorMessage("tooOld");

    signup(email, selectedDate);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (signupStatus === SUCCESS) {
    ReactGA.pageview(window.location.pathname + "/thank-you");
  }

  return (
    <form className={classes.rootContainer} onSubmit={onSubmit}>
      <Grid container className={classes.root} alignItems="center">
        <Grid item container spacing={2}>
          {signupStatus === SUCCESS ? (
            <>
              <Grid item xs={12} align="center">
                <img
                  src={SuccessImg}
                  alt="Magic Link sent"
                  className={classes.magicLinkSuccessImg}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" align="center">
                  <FormattedMessage id="signup.success.title" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                  <FormattedMessage id="signup.success.subtitle" />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h1" align={isMobile ? "center" : "left"}>
                  <FormattedMessage id="signup.title" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  align={isMobile ? "center" : "left"}
                >
                  <FormattedMessage id="signup.subtitle" />
                </Typography>
              </Grid>

              <Grid item xs={12} md={7}>
                <TextField
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={7}>
                <MuiPickersUtilsProvider utils={MomentDateUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/yyyy"
                    margin="none"
                    label="Date prévue pour l'accouchement"
                    required
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              {errorMessage && (
                <Grid item xs={12} md={7}>
                  <MuiAlert elevation={0} variant="standard" severity="error">
                    <FormattedHTMLMessage id={`signup.error.${errorMessage}`} />
                  </MuiAlert>
                </Grid>
              )}

              <Grid item xs={12}>
                {signupStatus === LOADING ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <RoundedButton type="submit" fullWidth={isMobile}>
                    <FormattedMessage id="signup.cta" />
                  </RoundedButton>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default inject("authStore")(observer(Signup));
