import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { throttle } from "lodash";
import { get } from "lodash/fp";

import authStore from "./stores/auth.store";
import commonStore from "./stores/common.store";
import babyStore from "./stores/baby.store";

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = process.env.REACT_APP_API_ROOT;

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    authStore.logout();
    if (
      window.location.pathname.search("signin") === -1 &&
      window.location.pathname.search("signup") === -1
    ) {
      window.location.pathname = "/signin";
    }
  }
  return err;
};

const responseBody = (res) => res.body;

/**
 * @description Set token to header
 */
const tokenPlugin = (req) => {
  if (commonStore.getAccessToken() || commonStore.accessToken) {
    req.set(
      "Authorization",
      `Bearer ` + (commonStore.getAccessToken() || commonStore.accessToken)
    );
  }
  if (get("currentBaby.id", babyStore)) {
    req.set("Baby", get("currentBaby.id", babyStore));
  }
};

/**
 * @description Create requests, will be used by all other actions in this file
 */
const requests = {
  del: (url) => {
    return validateToken().then(() =>
      superagent
        .del(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${url}`
        )
        .timeout({
          response: 30000,
        })
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody)
    );
  },

  get: (url) => {
    return validateToken().then(() =>
      superagent
        .get(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${url}`
        )
        .timeout({
          response: 30000,
        })
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody)
    );
  },

  put: (url, body) => {
    return validateToken().then(() =>
      superagent
        .put(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${url}`,
          body
        )
        .timeout({
          response: 30000,
        })
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody)
    );
  },

  post: (url, body) => {
    return validateToken().then(() =>
      superagent
        .post(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${url}`,
          body
        )
        .timeout({
          response: 30000,
        })
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody)
    );
  },
  postFile: (url, file) => {
    return validateToken().then(() =>
      superagent
        .post(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${url}`
        )
        .attach("image", file)
        .timeout({
          response: 30000,
        })
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody)
    );
  },
};

/**
 * @description Get new access token if the older one is expired
 */
let validateTokenMethod = () => {
  if (commonStore.getRefreshToken() && !commonStore.getAccessToken()) {
    console.log("validateTokenMethod");
    return new Promise((resolve, reject) => {
      superagent
        .post(
          (process.env.NODE_ENV === "development" ? "http://" : "https://") +
            `${API_ROOT}/api/auth/locale`,
          {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: "refresh_token",
            refresh_token: commonStore.getRefreshToken(),
          }
        )
        .timeout({
          response: 30000,
        })
        .end((err) => {
          if (err) handleErrors(err);
        })
        .then((response) => {
          const tokenResp = JSON.parse(response.text);
          commonStore.setAuthTokens({
            token: tokenResp.access_token,
            expires: tokenResp.expires,
          });
          resolve();
        })
        .catch((err) => {
          console.error("The refresh token exchange has not worked.", err);
          authStore.logout();
          window.location.pathname = "/signin";
        });
    });
  } else {
    return Promise.resolve();
  }
};

const validateToken = throttle(validateTokenMethod, 30000);

const Auth = {
  signinWithMagicLink: (email) =>
    requests.post(`${API_ROOT}/api/auth/signin/magicLink`, {
      email,
    }),
  signinWithMagicLinkExchange: (hash, token) =>
    requests.post(`${API_ROOT}/api/auth/signin/magicLink/exchange`, {
      hash,
      token,
    }),
  signup: (email, expectedBirthDate) =>
    requests.post(`${API_ROOT}/api/auth/signup`, { email, expectedBirthDate }),
};

const User = {
  getOne: (userId) => requests.get(API_ROOT + "/api/users/" + userId),
  me: () => requests.get(`${API_ROOT}/api/users/me`),
};

const Baby = {
  me: () => requests.get(`${API_ROOT}/api/babies/me`),
  updateCurrentBaby: (baby) =>
    requests.put(`${API_ROOT}/api/babies/me`, { baby }),
};

const Task = {
  getByBabyId: (babyId) =>
    requests.get(`${API_ROOT}/api/tasks?babyId=${babyId}`),
  update: (id, task) => requests.put(`${API_ROOT}/api/tasks/${id}`, { task }),
  create: (task) => requests.post(`${API_ROOT}/api/tasks`, { task }),
};

const Category = {
  get: () => requests.get(`${API_ROOT}/api/categories`),
};

const Purchase = {
  create: (purchase) =>
    requests.post(`${API_ROOT}/api/purchases`, { purchase }),
  getByTaskId: (taskId) =>
    requests.get(`${API_ROOT}/api/purchases?taskId=${taskId}`),
  delete: (id) => requests.del(`${API_ROOT}/api/purchases/${id}`),
};

const Favorite = {
  create: (favorite) =>
    requests.post(`${API_ROOT}/api/favorites`, { favorite }),
  getByTaskId: (taskId) =>
    requests.get(`${API_ROOT}/api/favorites?taskId=${taskId}`),
  delete: (id) => requests.del(`${API_ROOT}/api/favorites/${id}`),
  update: (id, fav) =>
    requests.put(`${API_ROOT}/api/favorites/${id}`, { favorite: fav }),
};

const Product = {
  create: (product) => requests.post(`${API_ROOT}/api/products`, { product }),
  update: (id, product) =>
    requests.put(`${API_ROOT}/api/products/${id}`, { product }),
};

const Picture = {
  create: (file) => requests.postFile(`${API_ROOT}/api/pictures`, file),
};

const PushSubscription = {
  create: (subscription) =>
    requests.post(`${API_ROOT}/api/notifications/subscribe`, {
      subscription,
    }),
};

const Event = {
  create: (event) =>
    requests.post(`${API_ROOT}/api/events`, {
      event,
    }),
};

export default {
  Auth,
  User,
  Baby,
  Task,
  Category,
  Product,
  Purchase,
  Picture,
  Favorite,
  PushSubscription,
  Event,
};
