import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  iconBtn: {
    width: 58,
    height: 58,
    backgroundColor: theme.palette.secondary.light,
    marginRight: 8,
    fontSize: "1.6rem",
  },
  text: {
    lineHeight: "1",
  },
  inputFile: {
    display: "none",
  },
  dialogRoot: {
    padding: 8,
    boxSizing: "inherit",
    margin: 0,
    width: "100%",
  },
}));

const LinkField = ({ value, onChange, className, ...other }) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(value);

  const handleAddLink = () => {
    onChange(url);
    setIsOpen(false);
  };

  const title = value ? (
    <FormattedMessage id="task.product.link.update" />
  ) : (
    <FormattedMessage id="task.product.link.add" />
  );

  return (
    <>
      <div
        className={`${classes.root} ${className}`}
        {...other}
        onClick={() => setIsOpen(true)}
      >
        <IconButton size="small" color="secondary" className={classes.iconBtn}>
          <i className="fas fa-link"></i>
        </IconButton>
        <div>
          <Typography variant="caption" className={classes.text}>
            (optionnel)
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {title}
          </Typography>
        </div>
      </div>
      <Dialog open={isOpen} onClose={() => setIsOpen(null)}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
          className={classes.dialogRoot}
        >
          <Grid item xs={12}>
            <TextField
              value={url || ""}
              onChange={(e) => setUrl(e.target.value)}
              variant="outlined"
              label="Lien vers la page du produit"
              fullWidth
            />
          </Grid>
          <Grid item container direction="row" justify="center" xs={12}>
            <Grid item>
              <Button size="large" onClick={() => setIsOpen(false)}>
                <FormattedMessage id="cta.cancel" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleAddLink}
              >
                {title}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default LinkField;
