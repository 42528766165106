import { runInAction, makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import agent from "../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../asyncStatus";

class FavoriteStore {
  favoritesByTaskId = {};
  createFavoriteStatus = IDLE;
  fetchTaskFavoritesStatus = IDLE;
  deleteFavoriteStatus = IDLE;
  updateFavoriteStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description Return task favorites which are not purchased yet.
   * @return Return null means it doesn't fetch yet.
   */
  taskFavorites = computedFn((taskId) => {
    try {
      return this.favoritesByTaskId[taskId].filter((f) => !f.purchased);
    } catch (e) {
      return null;
    }
  });

  fetchTaskFavorites = async (taskId) => {
    try {
      this.fetchTaskFavoritesStatus = LOADING;

      const { data: favorites } = await agent.Favorite.getByTaskId(taskId);

      runInAction(() => {
        this.favoritesByTaskId = {
          ...this.favoritesByTaskId,
          [taskId]: favorites,
        };

        this.fetchTaskFavoritesStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchTaskFavoritesStatus = ERROR;
      });
    }
  };

  // TODO : need product to populate fields ...
  createFavorite = async (product, taskId) => {
    try {
      this.createFavoriteStatus = LOADING;

      const { data: createdFavorites } = await agent.Favorite.create({
        productId: product.id,
        taskId,
      });

      runInAction(() => {
        createdFavorites.forEach((cFavorite) => {
          this.addFavorite(cFavorite.taskId, cFavorite.id, {
            id: cFavorite.id,
            description: product.description,
            price: product.price,
            pictureUrl: product.pictureUrl,
            productUrl: product.productUrl,
            productId: product.id,
          });
        });

        this.createFavoriteStatus = SUCCESS;
      });
      return createdFavorites;
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.createFavoriteStatus = ERROR;
      });
    }
  };

  deleteFavorite = async (favId, taskId) => {
    try {
      this.deleteFavoriteStatus = LOADING;

      await agent.Favorite.delete(favId);

      runInAction(() => {
        const newTaskFavs = this.favoritesByTaskId[taskId] || [];

        this.favoritesByTaskId = {
          ...this.favoritesByTaskId,
          [taskId]: newTaskFavs.filter((elt) => elt.id !== favId),
        };
        this.deleteFavoriteStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.deleteFavoriteStatus = ERROR;
      });
    }
  };

  updateFavorite = async (favId, taskId, fav) => {
    try {
      this.updateFavoriteStatus = LOADING;

      const { data: updatedFavs } = await agent.Favorite.update(favId, fav);

      runInAction(() => {
        updatedFavs.forEach((fav) => {
          this.addFavorite(fav.taskId, fav.id, fav);
        });

        this.updateFavoriteStatus = SUCCESS;
      });

      return updatedFavs;
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.updateFavoriteStatus = ERROR;
      });
    }
  };

  addFavorite = (taskId, favId, fav) => {
    let elt =
      (this.favoritesByTaskId[taskId] || []).find((f) => f.id === favId) || {};

    elt = { ...elt, ...fav };

    this.favoritesByTaskId[taskId] = (
      (this.favoritesByTaskId[taskId] || []).filter((f) => f.id !== favId) || []
    ).concat([elt]);
  };

  removeFavorite = (taskId, favId) => {
    this.favoritesByTaskId[taskId] = this.favoritesByTaskId[taskId].filter(
      (f) => f.id != favId
    );
  };
}

export default new FavoriteStore();
