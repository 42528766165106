import { Button, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  highlighted: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const HighlightedButton = ({
  children,
  highlighted = true,
  className,
  ...props
}) => {
  const classes = useStyle();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Button
      variant="text"
      size={isMobile ? "medium" : "large"}
      className={`${classes.root} ${
        highlighted ? classes.highlighted : ""
      } ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default HighlightedButton;
