import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { inject, observer } from "mobx-react";

import useStyle from "./SearchField.css";

const SearchField = ({
  intl: { formatMessage },
  taskStore: { setSearchQuery },
}) => {
  const classes = useStyle();
  const [query, setQuery] = useState(null);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item>
          <i className={"fas fa-search " + classes.icon}></i>
        </Grid>
        <Grid item xs>
          <input
            type="search"
            className={classes.input}
            placeholder={formatMessage({ id: "search.placeholder" })}
            value={query || ""}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(inject("taskStore")(observer(SearchField)));
