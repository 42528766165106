import { makeAutoObservable, runInAction } from "mobx";
import agent from "../agent";
import commonStore from "./common.store";
import { IDLE, SUCCESS, ERROR, LOADING } from "../asyncStatus";

class AuthStore {
  signinWithMagicLinkStatus = IDLE;
  signinWithMagicLinkExchangeStatus = IDLE;
  signupStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {}

  isAuth() {
    if (commonStore.getAccessToken() || commonStore.getRefreshToken()) {
      return true;
    } else {
      return false;
    }
  }

  signup = async (email, expectedBirthDate) => {
    try {
      this.signupStatus = LOADING;

      await agent.Auth.signup(email, expectedBirthDate);

      runInAction(() => {
        this.signupStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      this.signupStatus = ERROR;
    }
  };

  signinWithMagicLink = async (email) => {
    try {
      this.signinWithMagicLinkStatus = LOADING;

      // request
      await agent.Auth.signinWithMagicLink(email);

      this.signinWithMagicLinkStatus = SUCCESS;
    } catch (e) {
      runInAction(() => {
        this.signinWithMagicLinkStatus = ERROR;
        console.error(e);
      });
    }
  };

  signinWithMagicLinkExchange = async (hash, token) => {
    try {
      this.signinWithMagicLinkExchangeStatus = LOADING;

      // get access token & refresh token from hash & token
      const {
        data: { accessToken, refreshToken },
      } = await agent.Auth.signinWithMagicLinkExchange(hash, token);

      commonStore.setAuthTokens(accessToken, refreshToken);

      runInAction(() => {
        this.signinWithMagicLinkExchangeStatus = SUCCESS;
      });
    } catch (e) {
      runInAction(() => {
        this.signinWithMagicLinkExchangeStatus = ERROR;
        console.error(e);
      });
    }
  };

  logout() {
    commonStore.removeAuthTokens();
    window.location.pathname = "/";
    return Promise.resolve();
  }
}

export default new AuthStore();
