import React from "react";
import { CircularProgress } from "@material-ui/core";
import { LOADING, ERROR } from "../../asyncStatus";

const AsyncHandler = ({ children, status }) => {
  if (status === LOADING) return <CircularProgress color="secondary" />;
  if (status === ERROR) return <>❌</>;

  return children;
};

export default AsyncHandler;
