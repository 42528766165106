import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import LinkField from "../../../../utils/LinkField";
import PictureField from "../../../../utils/PictureField";
import FavList from "./FavList";

const useStyle = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  helpText: {
    backgroundColor: theme.palette.primary.ultraLight,
    padding: "32px !important",
  },
  infoIcon: {
    color: theme.palette.primary.main,
    paddingRight: 32,
    fontSize: 32,
  },
  subfield: {
    maxWidth: 180,
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
    },
  },
  redError: {
    color: "#f44336",
  },
}));

const CreateAchatForm = ({
  taskId, // bad way to send taskId to the children ...
  purchase = {}, // should send id
  taskStore: { applyStrategyAndToggleInProgress },
  purchaseStore: { createPurchase, createPurchaseStatus, addPurchase },
  favoriteStore: { updateFavorite, taskFavorites },
  productStore: {
    createProduct,
    createProductStatus,
    updateProduct,
    updateProductStatus,
  },
  onClose,
}) => {
  const classes = useStyle();
  const isEditMode = !!purchase.productId;
  const [description, setDescription] = useState(purchase.description); // required if new product
  const [price, setPrice] = useState(purchase.price); // optionnal
  const [pictureUrl, setPictureUrl] = useState(purchase.pictureUrl); // optionnal
  const [productUrl, setProductUrl] = useState(purchase.productUrl); // optionnal
  const [displayError, setDisplayError] = useState(false);
  const [tooManyError, setTooManyError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [favsSelected, setFavsSelected] = useState([]);

  useEffect(() => {
    setTooManyError(
      !isEmpty(favsSelected) &&
        (price || pictureUrl || productUrl || description)
    );
  }, [favsSelected, price, pictureUrl, productUrl, description]);

  const onNewPurchase = async (e) => {
    // error state if none of the following fille : description OR favoriteSelected

    e.preventDefault();
    // if favorite selected, create purchase from favorite selected.

    if (!description && isEmpty(favsSelected)) return setDisplayError(true);

    setInProgress(true);

    if (!isEmpty(favsSelected)) {
      const results = favsSelected.map(async (favSelected) => {
        updateFavorite(favSelected.id, taskId, { purchased: true });
        await createPurchase(
          {
            id: favSelected.productId,
            description: favSelected.description,
            price: favSelected.price,
            pictureUrl: favSelected.pictureUrl,
            productUrl: favSelected.productUrl,
            brand: favSelected.brand,
          },
          taskId
        );
      });

      await Promise.all(results);
    } else {
      const product = await createProduct({
        description,
        price,
        pictureUrl,
        productUrl,
        taskId,
      });
      await createPurchase(product, taskId);
    }

    applyStrategyAndToggleInProgress(taskId);

    setInProgress(false);
    onClose();
  };

  const onEditPurchase = async (e) => {
    e.preventDefault();

    if (!description && isEmpty(favsSelected)) return setDisplayError(true);
    setInProgress(true);

    updateProduct(purchase.productId, {
      description,
      price,
      pictureUrl,
      productUrl,
    });

    addPurchase(taskId, purchase.id, {
      description,
      price,
      pictureUrl,
      productUrl,
    });

    setInProgress(false);
    onClose();
  };

  return (
    <form
      onSubmit={isEditMode ? onEditPurchase : onNewPurchase}
      className={classes.form}
    >
      <Grid container spacing={2}>
        {!isEditMode && (
          <Grid item xs={12}>
            <div className={classes.helpText}>
              <Grid item container xs={12} direction="row" alignItems="center">
                <Grid item>
                  <i className={`fas fa-info-circle ${classes.infoIcon}`}></i>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">
                    Nous vous conseillons de détailler votre acquisition car
                    cela vous permettra d’accéder à des fonctionnalités que nous
                    préparons : revente, prêt et mise en location en un clic.
                    C'est également très utile pour les vêtements pour que vous puissiez
                    voir à tout moment ce que vous avez déjà et ainsi pour que
                    vous puissiez n'acheter que des vêtements qui vont avec ou
                    sont complémentaires ! Le prix vous permet de suivre vos
                    dépenses.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        <Grid item>
          <Typography variant="h2">
            <FormattedMessage
              id={`purchase.title.${isEditMode ? "edit" : "add"}`}
            />
          </Typography>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6} sm="auto">
            <PictureField
              className={classes.subfield}
              onChange={(url) => setPictureUrl(url)}
              value={pictureUrl}
            />
          </Grid>
          <Grid item xs={6} sm="auto">
            <LinkField
              value={productUrl}
              onChange={(url) => setProductUrl(url)}
              className={classes.subfield}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextField
              fullWidth
              variant="outlined"
              className={classes.subfield}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              label="Prix"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              inputProps={{
                min: 0,
                step: "0.01",
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            error={displayError}
            helperText={
              displayError &&
              "Vous devez indiquer au moins un mot de description"
            }
            label="Description de mon acquisition *"
            fullWidth
            variant="outlined"
            rows={4}
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        {!isEditMode && (taskFavorites(taskId) || []).length > 0 && (
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">
                Ou sélectionner depuis vos favoris
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FavList
                selected={favsSelected}
                onSelect={(fav) => setFavsSelected([...favsSelected, fav])}
                onRemove={(fav) =>
                  setFavsSelected([
                    ...favsSelected.filter((f) => f.id != fav.id),
                  ])
                }
                taskId={taskId}
              />
            </Grid>
          </Grid>
        )}

        <Grid item container xs={12} justify="flex-end" alignItems="center">
          {tooManyError && (
            <Typography variant="caption" className={classes.redError}>
              <FormattedMessage id="purchase.error.tooManyFields" />
            </Typography>
          )}
          {inProgress ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <Button size="large" onClick={onClose}>
                <FormattedMessage id="cta.cancel" />
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={tooManyError}
              >
                <FormattedMessage
                  id={`purchase.title.${isEditMode ? "edit" : "add"}`}
                />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default inject(
  "purchaseStore",
  "productStore",
  "favoriteStore",
  "taskStore"
)(observer(CreateAchatForm));
