import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#252525",
  },
  mainContainer: {
    padding: 32,
    [theme.breakpoints.down("md")]: {
      padding: 16,
    },
  },
  bottomPart: {
    borderTop: "1px solid grey",
  },
  link: {
    color: "#CCC",
    padding: 8,
    fontWeight: 600,
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& > a": {
      textDecoration: "none",
    },
    "& a:hover": {
      color: theme.palette.secondary.main,
    },
  },
  forceHeight: {
    minHeight: 200,
  },
}));

const Footer = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        disableGutters
        className={`${classes.mainContainer} ${classes.forceHeight}`}
      >
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.link}>
              <a href="https://www.mababychecklist.fr/mentions-legales/">
                Mentions Légales
              </a>
            </div>

            <div className={classes.link}>
              <a href="https://www.mababychecklist.fr/cgu/">
                Conditions Générales d'Utilisation
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.link}>
              <a href="https://www.mababychecklist.fr/faire-un-don-soutenir/">
                Faire un don
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        disableGutters
        className={`${classes.mainContainer} ${classes.bottomPart}`}
      >
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.link}>© 2020 Ma Baby Checklist.</div>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4} container>
            <div className={classes.link}>
              <a
                href="https://twitter.com/MaBabyChecklist"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div className={classes.link}>
              <a
                href="https://www.facebook.com/MaBabyChecklist/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
            <div className={classes.link}>
              <a
                href="https://www.pinterest.com/MaBabyChecklist"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <i className="fab fa-pinterest"></i>
              </a>
            </div>
            <div className={classes.link}>
              <a
                href="https://www.linkedin.com/company/ticosto/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <div className={classes.link}>
              <a
                href="https://www.instagram.com/mababychecklist/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
