import { Grid, Typography } from "@material-ui/core";
import React from "react";
import useStyle from "./tasks/Task.css";

const CategoryHeader = ({ name, pictureUrl }) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Grid container direction="row" className={classes.rootContainer}>
        <Grid item className={classes.colZero}>
          <img
            src={pictureUrl}
            className={classes.categoryPicture}
            alt="Categorie"
          />
        </Grid>
        <Grid item className={classes.col}>
          <Typography variant="h3">{name}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryHeader;
