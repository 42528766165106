import {
  AppBar,
  Button,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "./MainMenu.css";
import MBCLine from "../../resources/images/mbc_line.jpg";
import HighlightedButton from "../buttons/HighlightedButton";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { inject, observer } from "mobx-react";
import { LOADING, SUCCESS } from "../../asyncStatus";

const noAuthItems = [
  {
    name: "Accueil",
    href: "https://www.mababychecklist.fr/",
  },
  {
    name: "Liste des indispensables",
    href: "https://www.mababychecklist.fr/liste-indispensables-naissance-bebe/",
  },
  {
    name: "Nos conseils",
    href: "https://www.mababychecklist.fr/conseils-equipements-vetements-bebe/",
  },
  {
    name: "Dons",
    href: "https://www.mababychecklist.fr/faire-un-don-soutenir/",
  },
];

const Header = ({
  authStore: { isAuth },
  userStore: { currentUser, fetchCurrentUserDataStatus },
  babyStore: { currentBaby },
}) => {
  const classes = useStyles();
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    isAuth()
      ? fetchCurrentUserDataStatus === SUCCESS && setRender(true)
      : setRender(true);
  }, [fetchCurrentUserDataStatus]);

  const showSuitcase = currentBaby && currentBaby.expectedBirthDate;

  if (!render) return null;

  // TODO : Sticky tremble
  // TODO : probleme de breakpoints sur le menu (bar menu et buttons)
  // TODO : Largeur dépasse 100%
  // TODO : Email passe dans Promotionnel 

  return (
    <AppBar position="sticky" color="inherit" className={classes.root}>
      <Container maxWidth="lg" disableGutters className={classes.container}>
        <Grid container justify="space-between">
          <Grid
            item
            style={{ alignSelf: "center" }}
            className={classes.headerLogoHeight}
          >
            <a
              href="https://mababychecklist.fr"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                src={MBCLine}
                alt="Ma Baby Checklist"
                className={classes.headerLogoHeight}
              />
            </a>
          </Grid>
          <Grid item>
            <Toolbar style={{ padding: 0 }}>
              <Hidden smDown>
                {currentUser ? (
                  <>
                    <Button
                      size="large"
                      variant="text"
                      component={NavLink}
                      to={"/checklist"}
                      activeClassName={classes.activeLink}
                      className={classes.desktopMenuBtn}
                    >
                      <FormattedMessage id="menu.checklist.link" />
                    </Button>
                    {showSuitcase && (
                      <Button
                        size="large"
                        variant="text"
                        component={NavLink}
                        to={"/suitcase"}
                        activeClassName={classes.activeLink}
                        className={classes.desktopMenuBtn}
                      >
                        <FormattedMessage id="menu.suitcase.link" />
                      </Button>
                    )}

                    <Button
                      size="large"
                      variant="text"
                      component={NavLink}
                      to={"/account"}
                      activeClassName={classes.activeLink}
                      className={classes.desktopMenuBtn}
                    >
                      <FormattedMessage id="menu.account.link" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="text"
                      size="large"
                      component={Link}
                      to={"/signin"}
                    >
                      <FormattedMessage id="menu.signin.cta" />
                    </Button>
                    <HighlightedButton component={Link} to={"/signup"}>
                      <FormattedMessage id="menu.signup.cta" />
                    </HighlightedButton>
                  </>
                )}
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setDisplayDrawer(!displayDrawer)}
                >
                  <MenuIcon />
                </IconButton>

                <Drawer
                  anchor="right"
                  open={displayDrawer}
                  onClose={() => setDisplayDrawer(false)}
                >
                  <div className={classes.drawerRoot} role="presentation">
                    <IconButton
                      onClick={() => setDisplayDrawer(false)}
                      className={classes.drawerCloseBtn}
                    >
                      <CloseIcon />
                    </IconButton>
                    <List>
                      {currentUser ? (
                        <>
                          <ListItem
                            component={Link}
                            push
                            to={"/checklist"}
                            onClick={() => setDisplayDrawer(false)}
                          >
                            <ListItemText primary={"Ma checklist"} />
                          </ListItem>
                          {showSuitcase && (
                            <ListItem
                              component={Link}
                              push
                              to={"/suitcase"}
                              onClick={() => setDisplayDrawer(false)}
                            >
                              <ListItemText
                                primary={"Ma valise de maternité"}
                              />
                            </ListItem>
                          )}

                          <ListItem
                            component={Link}
                            push
                            to={"/account"}
                            onClick={() => setDisplayDrawer(false)}
                          >
                            <ListItemText primary={"Mon compte"} />
                          </ListItem>
                        </>
                      ) : (
                        <>
                          {noAuthItems.map((item, index) => (
                            <ListItem
                              component="a"
                              key={item.name}
                              href={item.href}
                            >
                              <ListItemText primary={item.name} />
                            </ListItem>
                          ))}
                          <ListItem>
                            <Button
                              variant="outlined"
                              fullWidth
                              component={Link}
                              to={"/signin"}
                              onClick={() => setDisplayDrawer(false)}
                              push
                              className={classes.roundBtn}
                            >
                              <FormattedMessage id="menu.signin.cta" />
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              variant="outlined"
                              fullWidth
                              component={Link}
                              to={"/signup"}
                              onClick={() => setDisplayDrawer(false)}
                              push
                              className={classes.roundBtn}
                            >
                              <FormattedMessage id="menu.signup.cta" />
                            </Button>
                          </ListItem>
                        </>
                      )}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </Toolbar>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default inject("userStore", "babyStore", "authStore")(observer(Header));
