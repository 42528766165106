import { makeAutoObservable } from "mobx";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const DEFAULT_LOCALE = "fr";
export const ACCEPTED_LOCALES = ["fr"];
export const DOMAIN_NAME =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_HOST
    : "mababychecklist.fr";

class CommonStore {
  accessToken;
  refreshToken;
  locale = null;
  beforeInstallPromptEvent = null;

  constructor() {
    makeAutoObservable(this);
    this.init();
    this.populateLocale();
  }

  init = () => {
    this.accessToken = this.getCookie("accessToken");
    this.refreshToken = this.getCookie("refreshToken");
  };

  setLocale = (locale) => {
    this.locale = locale;
    this.setCookie("locale", locale);
  };

  populateLocale = () => {
    // then comes the cookie
    const cookieLocale = this.getCookie("locale");
    if (cookieLocale) {
      return this.setLocale(cookieLocale);
    }

    // then navigator's locale if accepted
    const navigatorLanguage =
      navigator.language || navigator.userLanguage || "";
    const navigatorExtractedLocale = navigatorLanguage.split("-")[0];
    if (ACCEPTED_LOCALES.includes(navigatorExtractedLocale)) {
      return this.setLocale(navigatorExtractedLocale);
    }

    // otherwise use default locale
    // @todo: we should get the db value instead
    return this.setLocale(DEFAULT_LOCALE);
  };

  getSessionStorage = (name) => sessionStorage.getItem(name);
  setSessionStorage = (name, value) => sessionStorage.setItem(name, value);
  removeSessionStorage = (name) => sessionStorage.removeItem(name);

  getLocalStorage(name, isObject) {
    name =
      (process.env.NODE_ENV === "development" ? "development-" : "") + name;
    if (isObject) return JSON.parse(localStorage.getItem(name));
    else return localStorage.getItem(name);
  }

  setLocalStorage(name, value, isObject) {
    name =
      (process.env.NODE_ENV === "development" ? "development-" : "") + name;
    return Promise.resolve().then(function () {
      try {
        if (isObject) localStorage.setItem(name, JSON.stringify(value));
        else localStorage.setItem(name, value);
      } catch (e) {
        console.error(e);
        localStorage.removeItem(name);
        console.log(`setLocalStorage : ${name} : ${isObject}`);
      }
    });
  }

  getAccessToken = () => this.getCookie("accessToken");
  getRefreshToken = () => this.getCookie("refreshToken");

  setCookie(name, value, { expires, path = "/" } = {}) {
    switch (process.env.NODE_ENV) {
      case "production":
        if (process.env.REACT_APP_IS_STAGING) {
          cookies.set(
            "staging-" + name,
            value,
            expires
              ? { expires: expires, path: path, domain: DOMAIN_NAME }
              : { path: path, domain: DOMAIN_NAME }
          );
        } else {
          cookies.set(
            name,
            value,
            expires
              ? { expires: expires, path: path, domain: DOMAIN_NAME }
              : { path: path, domain: DOMAIN_NAME }
          );
        }
        break;
      default:
        cookies.set(
          name,
          value,
          expires ? { expires: expires, path: path } : { path: path }
        );
        break;
    }
  }

  getCookie = (name) =>
    cookies.get((process.env.REACT_APP_IS_STAGING ? "staging-" : "") + name);

  removeCookie(name) {
    let options = {};
    if (process.env.NODE_ENV === "production") {
      options = { path: "/", domain: DOMAIN_NAME };
    } else {
      options = { path: "/" };
    }
    cookies.remove(
      (process.env.REACT_APP_IS_STAGING ? "staging-" : "") + name,
      options
    );
    this.init();
  }

  popCookie = (name) => {
    const cookieContent = this.getCookie(name);
    cookieContent && this.removeCookie(name);
    return cookieContent;
  };

  removeAuthTokens() {
    let options = {};
    if (process.env.NODE_ENV === "production") {
      options = { path: "/", domain: DOMAIN_NAME };
    } else {
      options = { path: "/" };
    }
    cookies.remove(
      (process.env.REACT_APP_IS_STAGING ? "staging-" : "") + "accessToken",
      options
    );
    cookies.remove(
      (process.env.REACT_APP_IS_STAGING ? "staging-" : "") + "refreshToken",
      options
    );
    this.init();
  }

  setAuthTokens(accessTokenObject, refreshTokenObject) {
    this.accessToken = accessTokenObject.token;

    this.setCookie("accessToken", this.accessToken, {
      expires: new Date(accessTokenObject.expires),
    });

    if (refreshTokenObject) {
      this.refreshToken = refreshTokenObject.token;
      this.setCookie("refreshToken", this.refreshToken, {
        expires: new Date(refreshTokenObject.expires),
      });
    }
  }

  setBeforeInstallPromptEvent = (beforeInstallPromptEvent) => {
    this.beforeInstallPromptEvent = beforeInstallPromptEvent;
  };
}

export default new CommonStore();
