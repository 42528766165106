import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: 67,
    borderBottom: "1px solid #EEEFF2",
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
  },
  rootContainer: {
    padding: 16,
  },
  taskMainContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      maxWidth: "calc(100% - 100px)",
    },
  },
  expandedRoot: {
    width: "100%",
  },
  highlighted: {
    backgroundColor: theme.palette.secondary.light,
  },
  descriptionContainer: {
    backgroundColor: theme.palette.primary.ultraLight,
    padding: 32,
  },
  name: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
  },
  col: {
    padding: "0px 16px",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 4px",
    },
  },
  usefulnessCol: {
    width: 185,
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
  },
  budgetCol: {
    width: 90,
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
  },
  arrowCol: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  colZero: {
    padding: 0,
    alignItems: "center",
    display: "flex",
  },
  tagPart: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: 4,
      paddingLeft: 0,
    },
  },
  deleteTaskRibbon: {
    width: "100%",
    backgroundColor: theme.palette.primary.ultraLight,
    textAlign: "center",
    padding: 16,
  },
  deleteTaskRibbonHighlighted: {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    textAlign: "center",
    padding: 16,
  },
  deleteConfirmRibbon: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  categoryPicture: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.primary.ultraLight,
    borderRadius: 50,
    margin: -8,
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },
  inProgress: {
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    borderRadius: 30,
    padding: "4px 8px",
    minWidth: 72,
    right: 0,
    "& p": {
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "2px 4px",
    },
  },
  deleteButton: {
    marginRight: 8,
  },
}));
