import { runInAction, makeAutoObservable } from "mobx";
import agent from "../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../asyncStatus";

class ProductStore {
  createProductStatus = IDLE;
  updateProductStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  createProduct = async (product) => {
    try {
      this.createProductStatus = LOADING;

      const { data: createdProduct } = await agent.Product.create(product);

      runInAction(() => {
        this.createProductStatus = SUCCESS;
      });
      return createdProduct;
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.createProductStatus = ERROR;
      });
    }
  };

  updateProduct = async (productId, product, taskId) => {
    try {
      this.updateProductStatus = LOADING;

      await agent.Product.update(productId, product);

      runInAction(() => {
        this.updateProductStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.updateProductStatus = ERROR;
      });
    }
  };
}

export default new ProductStore();
