import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    margin: "64px 0",
    backgroundColor: "white",
    minHeight: "70vh",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      minHeight: "calc(100vh - 56px)",
    },
  },
  screenshotContainer: {
    padding: 32,
    backgroundColor: theme.palette.primary.background,
    justifyContent: "center",
    display: "flex",
    "& img": {
      alignSelf: "center",
      width: "100%",
      borderRadius: 4,
    },
    [theme.breakpoints.down("md")]: {
      padding: 16,
      "& img": {
        width: "80%",
      },
    },
  },
}));
