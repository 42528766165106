import { Grid, CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { groupBy, orderBy } from "lodash";
import { IDLE, SUCCESS } from "../../../asyncStatus";
import CategoryHeader from "../elements/CategoryHeader";
import TaskRow from "../elements/tasks/TaskRow";
import CreateTask from "../elements/CreateTask";

const Checklist = ({
  taskStore: {
    filteredTasks,
    fetchCurrentBabyTasks,
    fetchCurrentBabyTasksStatus,
    updateTaskStatus,
    createTaskStatus,
    filters,
    searchQuery,
    isFilterSelected,
    tasksCategories,
    resetFilters,
  },
}) => {
  const [tasksObject, setTasksObject] = useState([]);

  useEffect(() => {
    fetchCurrentBabyTasksStatus === IDLE && fetchCurrentBabyTasks();
    resetFilters();
  }, []);

  // TODO : It should need updateTaskStatus / createTaskStatus to trigger the effect. filteredTasks recalcul should works.
  useEffect(() => {
    if (
      fetchCurrentBabyTasksStatus !== SUCCESS &&
      updateTaskStatus !== SUCCESS &&
      createTaskStatus !== SUCCESS
    )
      return;

    // Order tasks
    const sortedTasks = orderBy(
      filteredTasks({ noSubTasks: true, isSuitcase: true }).map((t) => ({
        ...t,
        usefulness: t.usefulness === null ? -1 : t.usefulness,
        budget: t.budget === null ? -1 : t.budget,
      })),
      ["usefulness", "budget", "name"],
      ["desc", "desc", "asc"]
    ).map((t) => ({
      ...t,
      usefulness: t.usefulness === -1 ? null : t.usefulness,
      budget: t.budget === -1 ? null : t.budget,
    }));

    const tasksFormatted = groupBy(sortedTasks, "categoryOrder");

    setTasksObject(tasksFormatted);
  }, [
    filteredTasks,
    fetchCurrentBabyTasksStatus,
    updateTaskStatus,
    createTaskStatus,
    filters,
    filters.length,
    searchQuery,
  ]);

  return (
    <Grid container direction="column" style={{ marginBottom: 64 }}>
      <Grid item container spacing={2}>
        {fetchCurrentBabyTasksStatus !== SUCCESS ? (
          <CircularProgress color="secondary" />
        ) : (
          <Grid item style={{ width: "100%" }}>
            {Object.keys(tasksObject).map((categoryOrder) => {
              const category =
                tasksCategories({ isSuitcase: true }).find(
                  (c) => c.ordre == categoryOrder
                ) || {};

              return (
                <Grid container key={category.name}>
                  <Grid item container>
                    <CategoryHeader
                      name={category.name}
                      pictureUrl={category.pictureUrl}
                    />
                  </Grid>
                  <Grid item container>
                    {tasksObject[categoryOrder].map((task) => (
                      <TaskRow task={task} key={task.id} />
                    ))}
                  </Grid>
                </Grid>
              );
            })}
            {isFilterSelected("status", "todo") && (
              <CreateTask timeGroupId={null} isSuitcase />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default inject("taskStore")(observer(Checklist));
