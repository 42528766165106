import React, { useRef, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import ActionCard from "../../../../utils/ActionCard";
import CreateAchatForm from "./CreateAchatForm";
import { inject, observer } from "mobx-react";
import ProductCard from "../../../../utils/ProductCard";
import ProductDefaultPicture from "../../../../../resources/images/product_default.png";
import { useSnackbar } from "notistack";
import { FormattedMessage, injectIntl } from "react-intl";

const useStyle = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.secondary.light,
    height: 36,
    width: 36,
  },
}));

const scrollToTaskRow = (elt) => {
  console.log("scrollToTaskRow");
  const yOffset = -58;
  const y = elt.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: "smooth" });
};

const scrollToEnd = (elt) => {
  console.log("scrollToEnd");
  elt.scrollIntoView({
    behavior: "smooth",
    block: "end",
  });
};

const AchatsView = ({
  taskId,
  purchaseStore: { taskPurchases, deletePurchase, removePurchase, addPurchase },
  taskStore: { applyStrategyAndToggleInProgress },
  intl: { formatMessage },
}) => {
  const classes = useStyle();
  const itemRef = useRef();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [purchaseToEdit, setPurchaseToEdit] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const onDelete = async (purchaseId, taskId) => {
    const purchaseToDelete = taskPurchases(taskId).find(
      (p) => p.id == purchaseId
    );
    removePurchase(taskId, purchaseId);

    const timeout = setTimeout(async () => {
      await deletePurchase(purchaseId, taskId);
      applyStrategyAndToggleInProgress(taskId);
    }, 5000);

    enqueueSnackbar(formatMessage({ id: "snackbar.label.purchase.removed" }), {
      variant: "warning",
      action: (key) => (
        <Button
          style={{ color: "white" }}
          size="large"
          variant="text"
          onClick={() => {
            clearTimeout(timeout);
            addPurchase(taskId, purchaseId, purchaseToDelete);
            closeSnackbar(key);
          }}
        >
          <FormattedMessage id="cta.cancel" />
        </Button>
      ),
    });
  };

  const openCreate = () => {
    setIsCreateMode(true);
    scrollToTaskRow(itemRef.current);
  };

  const closeCreate = () => {
    setIsCreateMode(false);

    setTimeout(() => {
      isMobile && scrollToEnd(itemRef.current);
    }, 50);
  };

  return (
    <Grid container direction="row" ref={itemRef}>
      {isCreateMode ? (
        <CreateAchatForm taskId={taskId} onClose={closeCreate} />
      ) : purchaseToEdit ? (
        <CreateAchatForm
          taskId={taskId}
          purchase={purchaseToEdit}
          onClose={() => setPurchaseToEdit(null)}
        />
      ) : (
        <Grid item container spacing={2}>
          {(taskPurchases(taskId) || []).map((achat) => (
            <Grid item xs={12} sm={6} md={3} key={achat.id}>
              <ProductCard
                title={achat.title || achat.description}
                achat={achat}
                price={achat.price}
                pictureUrl={achat.pictureUrl || ProductDefaultPicture}
                productUrl={achat.productUrl}
                onDelete={() => onDelete(achat.id, taskId)}
                onEdit={() => setPurchaseToEdit(achat)}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <ActionCard
              onClick={openCreate}
              label="J'en ai 1 !"
              iconButton={
                <IconButton
                  size="small"
                  color="secondary"
                  className={classes.btn}
                >
                  <i className="fas fa-plus"></i>
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(
  inject("purchaseStore", "taskStore")(observer(AchatsView))
);
