import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import useStyle from "./Auth.css";
import RoundedButton from "../buttons/RoundedButton";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { LOADING, SUCCESS } from "../../asyncStatus";
import SuccessImg from "../../resources/images/signin_success_magic_link.png";

const Signin = ({
  authStore: { signinWithMagicLink, signinWithMagicLinkStatus },
}) => {
  const classes = useStyle();
  const [email, setEmail] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (signinWithMagicLinkStatus === SUCCESS) {
    ReactGA.pageview(window.location.pathname + "/thank-you");
  }

  return (
    <form
      className={classes.rootContainer}
      onSubmit={(e) => {
        e.preventDefault();
        signinWithMagicLink(email);
      }}
    >
      <Grid container className={classes.root} alignItems="center">
        <Grid item container spacing={2}>
          {signinWithMagicLinkStatus === SUCCESS ? (
            <>
              <Grid item xs={12} align="center">
                <img
                  src={SuccessImg}
                  alt="Magic Link sent"
                  className={classes.magicLinkSuccessImg}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h1" align="center">
                  <FormattedMessage id="signin.magicLink.success.title" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center">
                  <FormattedMessage id="signin.magicLink.success.subtitle" />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h1" align={isMobile ? "center" : "left"}>
                  <FormattedMessage id="signin.title" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  align={isMobile ? "center" : "left"}
                >
                  <FormattedMessage id="signin.magicLink.subtitle" />
                </Typography>
              </Grid>

              <Grid item xs={12} md={7}>
                <TextField
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                {signinWithMagicLinkStatus === LOADING ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <RoundedButton type="submit" fullWidth={isMobile}>
                    <FormattedMessage id="signin.cta" />
                  </RoundedButton>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default inject("authStore")(observer(Signin));
