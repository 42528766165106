import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    textAlign: "center",
    borderRadius: 30,
    padding: "4px 8px",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 4px",
    },
  },
  label: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fullWidth: {
    width: "100%",
  },
  icon: {
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginRight: 4,
    },
  },
}));

const Tag = ({ label, fullWidth, className, startIcon, ...props }) => {
  const classes = useStyle();
  return (
    <div
      className={`${fullWidth ? classes.fullWidth : ""} ${
        className ? className : classes.root
      }`}
      {...props}
    >
      <Typography variant="body2" className={classes.label}>
        {startIcon && <div className={classes.icon}>{startIcon}</div>}
        {label}
      </Typography>
    </div>
  );
};

export default Tag;
