import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import Signin from "../components/auth/Signin";
import Signup from "../components/auth/Signup";
import Welcome from "../components/auth/Welcome";
import useStyle from "./Auth.page.css";
import { Grid, useMediaQuery } from "@material-ui/core";

const PICTURE_DESKTOP =
  "https://mababychecklist.s3-eu-west-1.amazonaws.com/picture_auth_desktop.png";
const PICTURE_MOBILE =
  "https://mababychecklist.s3-eu-west-1.amazonaws.com/picture_auth_mobile.png";

const AuthPage = ({ authStore: { isAuth } }) => {
  const classes = useStyle();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isAuth()) return <Redirect to={"/"} />;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4} className={classes.screenshotContainer}>
        <img
          src={isMobile ? PICTURE_MOBILE : PICTURE_DESKTOP}
          alt="Ma Baby Checklist App"
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Switch>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route path="/signin">
            <Signin />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default inject("authStore")(observer(AuthPage));
