import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  mainContainer: {
    padding: "0 32px",
    minHeight: "calc(100vh - 56px)",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  snackbar: {
    backgroundColor: theme.palette.primary.dark + "!important",
    padding: 16,
    color: "white",
    "& img": {
      marginRight: 8,
    },
  },
}));
