import { runInAction, makeAutoObservable } from "mobx";
import agent from "../agent";
import { ERROR, IDLE, LOADING, SUCCESS } from "../asyncStatus";
import commonStore from "./common.store";

class BabyStore {
  currentBaby = null;
  fetchCurrentBabyStatus = IDLE;
  updateCurrentBabyStatus = IDLE;

  constructor() {
    makeAutoObservable(this);
  }

  fetchCurrentBaby = async () => {
    try {
      this.fetchCurrentBabyStatus = LOADING;

      const { data: baby } = await agent.Baby.me();

      const expiresDate = new Date();
      expiresDate.setFullYear(expiresDate.getFullYear() + 1);

      commonStore.setCookie("currentBabyId", baby.id, {
        expires: expiresDate,
      });

      runInAction(() => {
        this.currentBaby = baby;
        this.fetchCurrentBabyStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.fetchCurrentBabyStatus = ERROR;
      });
    }
  };

  updateCurrentBaby = async (babyUpdatedField) => {
    try {
      this.updateCurrentBabyStatus = LOADING;

      const { data: updatedBaby } = await agent.Baby.updateCurrentBaby(
        babyUpdatedField
      );

      runInAction(() => {
        this.currentBaby = updatedBaby;
        this.updateCurrentBabyStatus = SUCCESS;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.updateCurrentBabyStatus = ERROR;
      });
    }
  };
}

export default new BabyStore();
