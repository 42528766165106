import React, { useEffect, useState } from "react";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import HighlightedButton from "../../../buttons/HighlightedButton";
import AchatsView from "./achats/AchatsView";
import FavorisView from "./favoris/FavorisView";
import NotesView from "./notes/NotesView";
import { inject, observer } from "mobx-react";
import { getOr, isEmpty } from "lodash/fp";
import AsyncHandler from "../../../utils/AsyncHandler";
import { IDLE, LOADING } from "../../../../asyncStatus";

const VIEWS = {
  buy: "buy",
  fav: "fav",
  note: "note",
};

const useStyle = makeStyles((theme) => ({
  menuRoot: {
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  counter: {
    marginLeft: 8,
    fontSize: "0.8rem",
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 50,
    padding: "0 7px",
  },
  arrow: {
    color: theme.palette.secondary.main,
    marginRight: -8,
    marginLeft: 8,
  },
  mobileMenu: {
    padding: 0,
    boxShadow: "0px 16px 20px rgba(0, 0, 0, 0.136528) !important",
    "& ul": {
      padding: 0,
    },
  },
  mobileMenuItem: {
    padding: 0,
    minHeight: "initial",
  },
  fullWidth: {
    width: "100%",
  },
}));

const TaskActions = ({
  taskId,
  notes,
  purchaseStore: { fetchTaskPurchases, taskPurchases },
  favoriteStore: { fetchTaskFavorites, taskFavorites },
}) => {
  const [view, setView] = useState(VIEWS.buy);
  const classes = useStyle();
  const [currentStatus, setCurrentStatus] = useState(LOADING);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // TODO : fetch purchases / favorites / notes to be able to display there count here.
  useEffect(() => {
    (async () => {
      await fetchTaskPurchases(taskId);
      await fetchTaskFavorites(taskId);
      setCurrentStatus(IDLE);
    })();
  }, []);

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        xs={12}
        direction="row"
        spacing={isMobile ? 0 : 2}
        className={classes.menuRoot}
      >
        <Grid item>
          <HighlightedButton
            onClick={() => setView(VIEWS.buy)}
            highlighted={view === VIEWS.buy}
          >
            Mes achats
            <div className={classes.counter}>
              {getOr(0, "length", taskPurchases(taskId))}
            </div>
          </HighlightedButton>
        </Grid>
        <Grid item>
          <HighlightedButton
            onClick={() => setView(VIEWS.fav)}
            highlighted={view === VIEWS.fav}
          >
            Mes favoris
            <div className={classes.counter}>
              {getOr(0, "length", taskFavorites(taskId))}
            </div>
          </HighlightedButton>
        </Grid>
        <Grid item>
          <HighlightedButton
            onClick={() => setView(VIEWS.note)}
            highlighted={view === VIEWS.note}
          >
            Mes notes
            <div className={classes.counter}>{isEmpty(notes) ? "0" : "1"}</div>
          </HighlightedButton>
        </Grid>
      </Grid>
      <AsyncHandler status={currentStatus}>
        {view === VIEWS.buy ? (
          <AchatsView taskId={taskId} />
        ) : view === VIEWS.fav ? (
          <FavorisView taskId={taskId} />
        ) : (
          <NotesView taskId={taskId} />
        )}
      </AsyncHandler>
    </Grid>
  );
};

export default inject("purchaseStore", "favoriteStore")(observer(TaskActions));
